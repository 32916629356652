import React from "react";
class Footer extends React.Component {
  render() {
    return(
      <footer>
        Footer
      </footer>
    );
  }
}
export default Footer;