import React from 'react';

class HistoryYear extends React.Component {
  render() {
    return (
        <h2>sdsadasd</h2>
    );
  }
}

export default HistoryYear;