import React, {useState} from "react";
import operations_gcc from '../../assets/images/GCC_India/tech_gcc.jpeg';
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
class Tech extends React.Component {
    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    openModal1 = () => this.setState({ isOpen1: true });
    closeModal1 = () => this.setState({ isOpen1: false });
    openModal2 = () => this.setState({ isOpen2: true });
    closeModal2 = () => this.setState({ isOpen2: false });
    openModal3 = () => this.setState({ isOpen3: true });
    closeModal3 = () => this.setState({ isOpen3: false });
    openModal4 = () => this.setState({ isOpen4: true });
    closeModal4 = () => this.setState({ isOpen4: false });
    openModal5 = () => this.setState({ isOpen5: true });
    closeModal5 = () => this.setState({ isOpen5: false });
    openModal6 = () => this.setState({ isOpen6: true });
    closeModal6 = () => this.setState({ isOpen6: false });
    openModal7 = () => this.setState({ isOpen7: true });
    closeModal7 = () => this.setState({ isOpen7: false });
    openModal8 = () => this.setState({ isOpen8: true });
    closeModal8 = () => this.setState({ isOpen8: false });
    openModal9 = () => this.setState({ isOpen9: true });
    closeModal9 = () => this.setState({ isOpen9: false });
    render() {
        // const [smShow, setSmShow] = useState(false);
        // const [lgShow, setLgShow] = useState(false);
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            
            <div className="teams-main-page bhq-india">
                <div className="teams-main-page-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-xs-12 col-sm-2">
                                <h2 className="head-rotate">Tech</h2>
                            </div>
                            <div className="col-md-10 col-xs-12 col-sm-10 carousel-teams">
                                <div className="details">
                                    <div className="image">
                                        <img alt="GCC India Operations Team" src={operations_gcc} />
                                    </div>
                                    <div className="details-main">
                                        <p>Our tech entity works towards building connections with AB InBev’s ecosystem through superior digital products and value creation by harnessing the power of personalized data and artificial intelligence.</p>
                                    </div>
                                </div>
                                <div className="team-name-main">Tech Teams</div>
                                <Slider {...settings}>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Applications</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">APPLICATIONS</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Applications team oversees the IT Operations for 250+ applications managing 50+ vendors in various business functions like Finance, Commercial, Supply for SAP and Non-SAP applications and ensures the availability and performance of our applications for all our zones. We build solutions that last keeping our consumers in mind, prioritizing continuous improvement and innovation.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal1}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Compliance</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen1} onHide={this.closeModal1} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">COMPLIANCE</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Global Compliance team manages risks and ensures our company remains compliant. This team works in close partnership with various global and zonal stakeholders in finance, legal, people, operations, IT, supply, logistics, marketing. This team manages compliance areas like IT Internal Controls, SOX Compliance, Software Asset Management, Data Privacy, Digital Risk Management, Legal Compliance, and Audit Analytics.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal2}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Cybersecurity Operations</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen2} onHide={this.closeModal2} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Cybersecurity Operations</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Global SOC team provides 24x7 Eyes-on-Glass support monitoring for all suspicious activities within the AB InBev environment and takes precautionary/proactive measures to avoid security incidents. This team includes and is not limited to Incident Response, Vulnerability Management Services, Threat Hunting, Brand Protection, OT Security, and Pen Testing.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal3}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Digital Workplace</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen3} onHide={this.closeModal3} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Digital Workplace</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Digital Workplace Team ensures that AB InBev employees across the globe stay connected with organized and managed IT infrastructure. Our team of experts work round the clock to provide consistent, enhanced solutions for managed devices across all our global locations. We ensure that corporate data and devices are protected and compliant as per company standards.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal4}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Global DC &amp; Cloud</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen4} onHide={this.closeModal4} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Global DC &amp; Cloud</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Global TechOps team provides cost-effective and innovative solutions to host applications and services across the globe resulting in major cost savings to AB InBev. This team manages and maintains the underlying infrastructure round the clock and prevents and mitigates outages to minimize business risk.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal5}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Network Operations</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen5} onHide={this.closeModal5} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Network Operations</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Global Network Operations team works round the clock to provide consistent, high-speed network connectivity across all our global locations. This team not only assures reliable connectivity but also ensures our breweries, data centres, and offices are protected using next-generation security tools.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots">
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Employee Experience</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal6}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>People Transformation</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen6} onHide={this.closeModal6} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">People Transformation</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Global People Transformation Team has been instrumental in creating the vision and business transformation of the people function in AB InBev globally. This team is now involved extensively in the implementation of Workday, which will transform AB InBev’s operating model and bring better employee experience, data-driven and human-centric mindset, and the AB InBev of the Future.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal7}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Our Tech Supply &amp; Logistics</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen7} onHide={this.closeModal7} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Our Tech Supply &amp; Logistics</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Tech Supply and Logistics Team transforms AB InBev operations to create real-time, connected, and continuously optimizing Supply Chain of the Future, where empowered operators deliver value through world-class execution. This team achieves recurring annual productivity improvements and service-level improvements.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal8}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>New Capabilities - Innovations</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen8} onHide={this.closeModal8} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">New Capabilities - Innovations</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Beer Garage is our global tech innovation team that drives innovation and accelerates business transformation by building a strong community of ecosystem stakeholders like start-ups, SMEs, innovators, incubators, accelerators, academia, and venture capitalists. This team works with business units to find disruptive solutions for their business problems by partnering with the vibrant start-up ecosystems from across the globe.</Modal.Body>
                                        </Modal>
                                    </div>
                                    <div>
                                        <div class="list-teams">
                                            <div class="details-team-dots" onClick={this.openModal9}>
                                                <div class="box-teams">&nbsp;</div>
                                                <div class="team-name"><span>Data & Architecture</span></div>
                                                <div class="click-more">Know More</div>
                                            </div>
                                        </div>
                                        <Modal className="teams-modal-popup" show={this.state.isOpen9} onHide={this.closeModal9} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    <div className="heading">Data & Architecture</div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Our Data & Architecture team provides a business capability to enable data producers and consumers, orchestrating efficient value creation and exchange within a globally connected ecosystem. This team’s working is based on Squads that have multidisciplinary groups of people (PODs) focused on executing business use cases' goals. They are Scrum Masters (Program Managers), Product Managers, Data Engineers, Business Analysts, Data Architects, Quality Assurance Analysts, and Business Intelligence Analysts.</Modal.Body>
                                        </Modal>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Tech;