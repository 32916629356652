import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import sajith from '../../../assets/images/GCC_Leaders/sajith.png';
class SajithKurup extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Sajith Kurup" src={sajith} />
                    </div>
                    <div class="details">
                        <h4>Anat Rokeah</h4>
                        <p>HR Business Partner, TechOps and Data</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={sajith} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Anat Rokeah</h4>
                            <p class="sub-head">HR Business Partner, TechOps and Data</p>
                            <p class="content">Anat oversees the HR strategy for TechOps and Data, spanning three locations and eight technological areas. In 2020, she joined AB InBev, Israel, as the People Manager leading all areas of HR, successfully establishing a strong foundation here.</p>
                            <div className="bottom_text_cls">
                            <p class="content">With extensive experience in various HR roles and an educational background in Organizational Consulting and Development, Anat possesses a deep understanding of human behavior in the workplace and has a knack for identifying and developing talent. Her skills include change management, employee lifecycle management, and performance evaluation, all aimed at driving organizational success and fostering a positive workplace environment.</p>
                            <p class="content">Outside of work, she prioritizes parenting her children and maintaining a well-rounded work-life balance.</p>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default SajithKurup;