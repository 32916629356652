import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import ashish from '../../../assets/images/BU_Leaders/ashish.png';
class AshishPalta extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Alexander Komrakov" src={ashish} />
                    </div>
                    <div class="details">
                        <h4>Ashish Palta</h4>
                        <p>Vice President, Sales South Asia</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={ashish} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Ashish Palta</h4>
                            <p class="sub-head">Vice President, Sales South Asia</p>
                            <p class="content">Ashish started his journey with Anheuser Busch InBev (AB InBev) in 2015 and is currently the Vice- President of Sales, South Asia.<br/><br/>In his five years with AB InBev, Ashish has held various key positions within the company including Regional Director (North), Sales Director – High End India and Director – Revenue Management and Route to Market.<br/><br/>With an experience of more than a decade in leading sales and marketing teams across the consumer and healthcare industry, Ashish has played a pivotal role in driving strategic change and delivering exponential growth for the companies he has worked with. His expertise ranges from building sales excellence, revenue management and growth along with driving integration and synergies to develop new businesses.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default AshishPalta;