import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import vijoe from '../../../assets/images/GCC_Leaders/vijoe.png';
class VijoeMathew extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Vijoe Mathew" src={vijoe} />
                    </div>
                    <div class="details">
                        <h4>Vijoe Mathew</h4>
                        <p>Director, Analytics</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={vijoe} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Vijoe Mathew</h4>
                            <p class="sub-head">Director, Analytics</p>
                            <p class="content">Vijoe Mathew is a Director of Analytics with AB InBev Growth Analytics Centre since July 2020. Born in 1978, Vijoe is an Indian citizen and holds a bachelor’s degree in engineering from JNNCE India & a Master’s in Industrial Management from Katholieke Universiteit Leuven, Belgium. He joined AB InBev in 2019 as a Senior Analytics Manager and focused on building a Global Centre of Excellence (COE) for Operations Analytics. Currently, he is leading the Global Operations Analytics & Supply Chain of the Future Analytics COEs. Before joining the group in 2018, he worked at Tesco PLC as Head of Retail Analytics and with Honeywell as Advance Analytics Solutions Leader.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default VijoeMathew;