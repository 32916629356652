import React from 'react';
import Layout2 from '../components/Layout/Layout2';
import Banner from '../components/OurBrands/Banner';
import Content from '../components/OurBrands/Content';
import GlobalBrands from '../components/OurBrands/GlobalBrands';
import IndianBrands from '../components/OurBrands/IndianBrands';
import NonAlochoholic from '../components/OurBrands/NonAlochoholic';
import ViewAllJobs from '../components/OurBrands/ViewAllJobs';

class OurBrands extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            {/* <Content /> */}
            {/* <GlobalBrands /> */}
            <IndianBrands />
            {/* <NonAlochoholic /> */}
            <ViewAllJobs />
        </Layout2>
      </>
    );
  }
}

export default OurBrands;