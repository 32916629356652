import React from "react";
import ProgrammeContent from './ProgrammeContent';
import Peer_Support_Network from '../../assets/images/programmes/Website_Icons_Peer_Support_Network.png';
import Leadership_Development from '../../assets/images/programmes/Website_Icons_Leadership_Development.png';
import Safe_Inclusive_Space from '../../assets/images/programmes/Website_Icons_Safe_Inclusive_Space.png';
import Empowerment_for_Collective from '../../assets/images/programmes/Website_Icons_Empowerment_for_Collective_Growth.png';
class MainContent extends React.Component { 
    render() {
        return(
            <div>
                
                    {/* <ProgrammeContent
                        title="What is it?"
                        description="Our Global Management Trainee (GMT) Program is the best induction into the world of business. It fast-tracks the careers of the brightest, most driven university graduates. Through a 12-month rotation, we offer in-depth exposure to our business operations and global projects."
                    />
                    <ProgrammeContent
                        title="What does it include?"
                        description="From your induction with other GMTs from around the world, to your end-of-programme presentation, you will work on real projects, learn about running and transforming one of the world’s most successful global businesses, and you will be treated as one of our future leaders. To develop a deep understanding of our business, you’ll be immersed in different areas like Sales, Commercial, Supply, Operations and many more. You’ll also work with the Tech & Innovation teams to flex your creative and analytical muscles while learning what it takes to launch a new idea and drive impactful insights."
                    />
                    <ProgrammeContent
                        title="Who is it for?"
                        description="You’ll need to be ambitious, curious, bold and resilient, ready to take on tough challenges and be determined to deliver results. We’re looking for a global mindset and a desire to connect with people at all levels. We prize a sense of ownership and a desire to make an impact. More important than your degree, is having an entrepreneurial spirit, being results-driven and having undertaken some form of leadership position. You should graduate (before September 2022 and no more than two years prior) with a Bachelor, Masters (or equivalent) degree. You should also have no more than 2 years of professional experience (internships/volunteers/student jobs not included). You will need to be fluent in English. In addition, you may be expected to travel around in your country of application during the 12-month programme, as well as after the programme."
                    />
                    <ProgrammeContent
                        title="Ready to apply?"
                        description="Our selection process is designed to make sure you’re the right fit for our programme but more importantly, we are the right fit for you."
                    /> */}
                 
                 <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                
                                <p>In a professional landscape where women continue to encounter bias and confront various workplace obstacles, Lean In Circle emerges as a beacon of empowerment. It serves as a sanctuary where women from across the organization can openly share their challenges, seek and offer advice, and collectively celebrate triumphs.</p>
                                <p>AB InBev deeply values gender diversity, recognizing that women still face underrepresentation in leadership roles. Additionally, women often lack relatable managers, mentors, and sponsors who can understand their unique experiences.</p>
                                <p>What sets this program apart is not only its commitment to gender diversity but also the resounding support from our Diversity, Equity, and Inclusion (DEI) team. Together, we are steadfastly committed to fostering an environment where women within the organization can connect, network, and engage in peer-to-peer mentorship.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <h3>What to Expect</h3>
                                <p>Employees engaging in this program will embark on a transformative journey spanning six months. Monthly meetings, designed to last between 60 to 90 minutes, offer flexibility by allowing participation either in person or via video conference. Circle Leader training equips individuals with the skills required for effective leadership.</p>
                                <p>Throughout their participation, employees can rely on constant support from the Global DEI team, who will guide them through every stage of their experience. In addition, the program provides valuable skill-building resources and monthly guides to enhance the Circle meetings.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content key_takeaways">
                    <div className="container">
                    <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <h3>Key Takeaways</h3>
                        <ul>
                                    <li>
                                        <img src={Peer_Support_Network}/>
                                        <b>Peer Support Network </b>Engaging in the program allows women employees to build a strong peer support network across the organization, serving as a foundation for personal and professional growth.
                                    </li>
                                    <li>
                                    <img src={Leadership_Development}/>
                                        <b>Leadership Development </b> Women across the organization will have the opportunity to enhance their leadership skills and contribute to shaping the company's culture.
                                    </li>
                                    <li>
                                    <img src={Safe_Inclusive_Space}/>
                                        <b>Safe & Inclusive Space </b> Employees can look forward to finding a safe and inclusive space within the program Circles, where they can freely share their challenges, seek advice, and celebrate their achievements with like-minded peers.
                                    </li>
                                    <li>
                                    <img src={Empowerment_for_Collective}/>
                                        <b>Empowerment for Collective Growth </b> Participation in the program is an opportunity to actively contribute to these goals, fostering personal and professional development while contributing to a stronger, more diverse, and inclusive workplace.
                                    </li>
                                    
                                </ul>
                                </div>
                                </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainContent;