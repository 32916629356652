import React from "react";
import icon1 from '../../assets/images/GCC_India/CreatingNewProducts.png';
import icon2 from '../../assets/images/GCC_India/Overseeing.png';
import icon3 from '../../assets/images/GCC_India/MitigatingRisk.png';
import icon4 from '../../assets/images/GCC_India/SharingKnowledge.png';
import icon5 from '../../assets/images/GCC_India/AcceleratingInnovation.png';
import icon6 from '../../assets/images/GCC_India/icon6.png';
import icon7 from '../../assets/images/GCC_India/icon7.png';
import icon8 from '../../assets/images/GCC_India/icon8.png';

class Card extends React.Component {
  render() {
    const { icon, heading, paragraph } = this.props.data;

    const cardStyle = {
      width: "100%", // Set the width to 100%
      height: "350px", // Set the desired height
      marginBottom: "90px", // Add margin at the bottom for spacing
      backgroundColor: "black", // Black background color for each card
      color: "white", // Text color
      padding: "20px", // Padding inside each card
      boxSizing: "border-box", // Include padding in the card's dimensions
      
    };

    const iconStyle = {
      height: "20px", // Set the height of the icon
      width: "20px",
     
    };

    const yellowLineStyle = {
      height: "4px", // Set the height of the yellow line
      backgroundColor: "#E5B611", // Yellow color for the line
      width: "94px", // Set the width of the line
      margin: "0", // Center the line horizontally
      marginTop: "15px", // Add margin to separate the line from the text
    };


    return (
      <div className="card">
           <img src={icon} alt={heading} className="card-icon" />
         <div style={yellowLineStyle}></div>
        <h5>{heading}</h5>
        <p style={{fontSize:"13px"}}>{paragraph}</p>
      </div>
    );
  }
}

class CardRow extends React.Component {
  render() {
    // Replace these sample data with your actual card data
    const cardData = [
      {
        icon: icon1,
        heading: "Creating New Products & Services",
        paragraph: "We develop or license prototypes, and test new products and services for the organization, thereby contributing to the organization’s growth and advancement.",
      },
      {
        icon: icon2,
        heading: "Overseeing External Networks and Partnerships",
        paragraph: "We build and manage new markets and resources through strong ecosystem partnerships while ensuring that these relationships are mutually beneficial. ",
      },
      {
        icon: icon3,
        heading: "Mitigating Risk",
        paragraph: "We help the organization mitigate the risk of innovation by collaborating with external partners and testing new ideas before bringing them to the market.",
      },
      {
        icon: icon4,
        heading: "Sharing Knowledge",
        paragraph: "We share best practices with our internal and external partners, fostering knowledge exchange and collaborative growth.",
      },
      {
        icon: icon5,
        heading: "Accelerating Innovation",
        paragraph: "We speed up innovation within the organization by providing access to new ideas, technologies, and resources.",
      },
      // {
      //   icon: icon6,
      //   heading: "Advanced Threat Detection & Incident Response",
      //   paragraph: "We leverage cutting-edge technologies and advanced threat detection mechanisms to monitor our applications in real-time. It enables us to respond to breaches swiftly, enhancing incident response capabilities",
      // },
      // {
      //   icon: icon7,
      //   heading: "Compliance & Regulatory Adherence",
      //   paragraph: "We monitor compliance standards, ensuring applications align with regulations and data protection laws, thus assuring customers that their data is handled with the utmost care and in full compliance",
      // },
      // {
      //   icon: icon8,
      //   heading: "Secure Code Development",
      //   paragraph: "We prioritize secure coding, collaborating with development teams to instill best practices and guidelines throughout the software development life cycle. This ensures that security is at the core rather than an afterthought in all our applications.",
      // },
      // Add more card data as needed
    ];

    // Divide cardData into two arrays, each containing four cards
    const firstRow = cardData.slice(0, 4);
    const secondRow = cardData.slice(4, 8);

    const cardContainerStyle = {
      // Black background for the entire group
      backgroundColor: "black",
      marginLeft: "20%",// Add left margin to the card container
      
    };

    return (
      <section className="cyber_sequ" style={{ backgroundColor: "black" }}>
    <div className="container">
      <div className="row">
        <div className="col-md-1 col-xs-12 col-sm-1"style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h2 className="head-rotate">INNOVATION</h2>
        </div>
        <div class="col-md-11 col-sm-11" style={{marginTop:'35px'}}>
        <h2 style={{ color: "white"}}>Areas of Focus</h2>
          <div className="row row1">
            {firstRow.map((card, index) => (
              <div className="col-md-3" key={index}>
                
                <Card data={card} />
              </div>
            ))}
          </div>
          <div className="row row2">
            {secondRow.map((card, index) => (
              <div className="col-md-3" key={index}>
                <Card data={card} />
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
      </section>
    );
  }
}

export default CardRow;
