import React from 'react';
import Layout2 from '../components/Layout/Layout2';
import Banner from '../components/OurHistory/Banner';
import BrewingHistory from '../components/OurHistory/BrewingHistory';
import Goals from '../components/OurHistory/Goals';
import History from '../components/OurHistory/History';

class OurHistory extends React.Component {
  render() {
    return (
      <>
        <Layout2>
          <Banner />
          <BrewingHistory />
          <History />
          <Goals />
        </Layout2>
      </>
    );
  }
}

export default OurHistory;