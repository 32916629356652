import React from "react";
import our_commitment from '../../assets/images/diversity_inclusion/our_commitment.mp4';


class OurCommitment extends React.Component {
    render() {
        return(
            <div className="diversity-inclusion">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-12 col-xs-12 col-sm-12 measured_cls">
                            <div className="top-content-di aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                <h2>We are measured by the quality and diversity of our teams.</h2>
                                <p>Every individual at every level in our company is responsible for treating others with respect and cultivating, encouraging, maintaining, and preserving an inclusive environment. An inclusive environment is one where everyone is treated with respect and valued for their diverse perspectives and ideas.</p>
                            </div>
                        </div> */}
                        <div className="col-md-12 col-xs-12 col-sm-12 no-padding DEI_STATEMENT">
                            <div className="di-list-table">
                                <div className="di-list-table-left aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="details">
                                        <h2>OUR <span>DEI Statement</span>
                                        </h2>
                                        <p>Our purpose is to create a future with more cheers. To achieve this, our company must be an inclusive and diverse workplace. Here, we all feel we belong, whatever our personal characteristics or social identities, such as race, nationality, gender identity, sexual orientation, age, abilities, socioeconomic status, religion, and others. Our greatest strength is our people; we support every individual to excel.</p>
                                        <ul>
                                            <li>We strive to be an inclusive workplace with equal opportunity. Everyone at AB InBev should feel comfortable, confident, and respected to bring their authentic selves to work every day and to grow at the pace of their talent.</li> 
                                            <li>We aim to make our company as diverse as the communities we serve. We thrive when our colleagues, including our leadership, reflect the diversity of our consumers and customers, enabling us to create solutions with our brands and services to best meet their needs.</li>
                                            <li>We dream bigger and better when we are together. We promote the diversity of teams and different perspectives that bring innovative ideas to deliver and transform our business.</li>
                                            <li>As owners, we are all responsible for DEI. DEI must be embedded into how we think, behave, and operate. Everyone at our company has the responsibility to champion an equitable workplace and root out discrimination of any kind.</li>
                                        </ul>
                                        <p>
                                        To deliver on our firm and unwavering commitment to our purpose and principles, an equitable, diverse, and inclusive culture is essential.
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="di-list-table-right aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="image">
                                        <video autoPlay className="d-i-video" loop="" width="400">
                                            <source src={our_commitment} type="video/mp4" />
                                            <source src="mov_bbb.ogg" type="video/ogg" /> Your browser does not support HTML video.
                                        </video>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default OurCommitment;