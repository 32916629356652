import React from "react";
import { Link } from "react-router-dom";
import banner_card from "../../assets/images/SustainabilityMapping.png";
import CDPClimate from "../../assets/pdf/CDP-response-Climate-2021.pdf";
import CDPWater from "../../assets/pdf/CDP-Water-Response-2021.pdf";
import External_Engagement from "../../assets/images/sustainability/External_Engagement.png";
import SUSTAINABILITY from "../../assets/images/sustainability/OUR-SUSTAINABILITY-GOALS.png";
import SUSTAINABILITY1 from "../../assets/images/sustainability/OUR-SUSTAINABILITY-GOALS1.png";
import MaterialityAssessment from "../../assets/images/sustainability/Our_2020_MaterialityAssessment.png";
import Sustainability_Is_Our_Business from "../../assets/images/sustainability/Sustainability_Is_Our_Business.png";
import Sustainability_Governance from "../../assets/images/sustainability/Sustainability_Governance.png";
class SustainabilityDetails extends React.Component {
  render() {
    return (
      <div className="sustainability-content">
        <div className="container">
          <div className="row align-items-center mt-4">
            <div className="col-md-5">
              <img
                src={Sustainability_Is_Our_Business}
                className="img-fluid img-thumbnail"
              />
            </div>
            <div className="col-md-7">
              <h3>Sustainability Is Our Business</h3>
              <p>
                In 2017, we formally engaged stakeholders through our
                materiality review process and the results informed our 2025
                Sustainability Goals. Since our 2017 assessment, our business
                and the world have evolved tremendously. Therefore, in early
                2020 we conducted a new materiality assessment to reflect the
                aspects most material to our stakeholders in order to continue
                to focus on critical economic, social, and environmental issues
                as a business and community partner.
              </p>
            </div>
          </div>

          <div className="row align-items-center mt-4">
            <div className="col-md-7">
              <h3>Our 2020 Materiality Assessment</h3>
              <p>
                In 2020, the materiality assessment process* mapped key
                economic, social, and environmental issues on a matrix based on
                the degree of stakeholder interest and potential business
                impact. External stakeholders include civil
                society/non-governmental organizations (NGOs), customers
                (retailers) and consumers, regulators, business partners,
                suppliers, and investors. This matrix illustrates the view of
                internal and external stakeholders interviewed, with the
                rankings helping to inform the evolution of our sustainability
                and responsibility strategy. It is important to highlight that
                we do not view these issues in isolation, as in many cases they
                are interconnected.
              </p>
            </div>
            <div className="col-md-5">
              <img
                src={MaterialityAssessment}
                className="img-fluid img-thumbnail"
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 col-xs-12 col-sm-12">
              <ul>
                <li className="banner_class">
                  <img src={banner_card} alt="banner_card" />
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center mt-1">
              <div className="col-md-5">
                <img
                  src={Sustainability_Governance}
                  className="img-fluid img-thumbnail"
                />
              </div>
              <div className="col-md-7">
                <h3>Sustainability Governance</h3>
                <p>
                  The Global Sustainability team reports twice a year to the
                  Finance Committee of the Board of Directors to review topics
                  relating to supply chain security and sustainability. <br />
                  <br />
                  We also have established a Sustainability Council led by our
                  Chief Procurement and Sustainability Officer and is comprised
                  of the Chief Legal &amp; Corporate Affairs Officer, Chief
                  Supply Officer, Chief Marketing Officer, Chief Finance
                  Officer, and Chief People Officer. The Sustainability Council
                  meets monthly to oversee the achievement of our goals and to
                  progress our broader sustainability strategy. Bi-monthly
                  updates are provided to the CEO.
                </p>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-md-7">
                <h3>External Engagement</h3>
                <p>
                  We consult regularly with an external group of experts as
                  environmental advisors, as well as other key NGOs, to bring in
                  a diverse perspective and to advise on decision making, target
                  setting, and program strategies and implementation across the
                  four pillars of our Sustainability Goals. Additionally, we
                  leverage CDP to manage our climate and water governance. Below
                  are our most recent submissions for the CDP investor
                  questionnaires:
                </p>
                <p className="mar_b">
                  <a
                    href={CDPClimate}
                    download="CDP-Climate-Response"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AB InBev CDP Climate Response 2021
                  </a>
                </p>
                <p className="mar_b">
                  <a
                    href={CDPWater}
                    download="CDP-Water-Response"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AB InBev CDP Water Response 2021
                  </a>
                </p>
              </div>
              <div className="col-md-5">
                <img
                  src={External_Engagement}
                  className="img-fluid img-thumbnail"
                />
              </div>
            </div>
        </div>
        <section className=" bottom_top mt-5 pt-5 pb-5">
          <div className="container">
          <div className="row">
            <div className="col-md-12">
            <p>
                  To continue strengthening a collaborative approach to
                  sustainable development, we engage in industry associations
                  and organizations such as the World Business Council for
                  Sustainable Development (WBCSD), the World Economic Forum
                  (WEF), the Beverage Industry Environmental Roundtable (BIER),
                  AIM-Progress, BSR, the Climate Group, RE100, the 2030 Water
                  Resources Group, and Sustainable Food Lab, as well as other
                  regional and sector-specific associations.
                </p>
                <i>Remuneration linked to Sustainability </i>
                <p>
                  Sustainability-related targets tied to annual variable
                  compensation are cascaded from the highest level of our
                  company to all operating units.
                </p>
            </div>
          </div>
          </div>
        </section>
      </div>
    );
  }
}
export default SustainabilityDetails;
