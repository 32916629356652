import React from "react";
import people_hr from '../../assets/images/BU_India/people_hr.jpeg';
class People extends React.Component {
    render() {
        return(
            <div class="bu-common-bu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div class="bu-common-main">
                                <div class="bu-right">
                                    <h4>People</h4>
                                    <p class="d-none d-sm-block">Our People team recruits, develops, enables, engages, grows, and rewards our amazing people – as they’re our greatest strength. This team ensures we have the right talent, in the right place, at the right time.</p>
                                </div>
                                <div class="bu-left">
                                    <div class="image">
                                        <img alt="People" src={people_hr} width="100%" />
                                    </div>
                                    <p class="d-block d-sm-none">Our People team recruits, develops, enables, engages, grows, and rewards our amazing people – as they’re our greatest strength. This team ensures we have the right talent, in the right place, at the right time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default People;