import React from "react";
import finance from '../../assets/images/GCC_India/BrewDat.png';
class Finance extends React.Component {
    render() {
        // return(
        //     <div class="bu-common-bu">
        //         <div class="container">
        //             <div class="row">
        //                 <div class="col-md-12 col-xs-12 col-sm-12">
        //                     <div class="bu-common-main">
        //                         <div class="bu-left">
        //                             <h4 class="d-block d-sm-none">Finance</h4>
        //                             <div class="image">
        //                                 <img alt="Finance" src={finance} width="100%" />
        //                             </div>
        //                         </div>
        //                         <div class="bu-right" data-aos="fade-up">
        //                             <h4 class="d-none d-sm-block">The BrewDat Team</h4>
        //                             <p>BrewDat is AB InBev's official data platform, revolutionizing data-driven decision-making. It empowers teams across functions, enabling them to collect, store, process, and manage data seamlessly. With transformed ready-to-use datasets, it simplifies analytics projects. It allows data scientists to experiment with models and visualize results using the reporting tool. </p>

        //                             <p>As the BrewDat team, we optimize resources to provide a comprehensive toolbox with engineer-friendly templates in a ready-to-use cloud environment. Our infrastructure strictly adheres to the company's security policies, ensuring compliance and maintaining the highest level of data security. Within the platform ecosystem, our team is actively involved in diverse areas, each with its specific responsibilities concerning data management and platform development.</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // );

        // ==============================================================================================

        return(
            
            <div className="teams-main-page bhq-india">
                <div className="teams-main-page-inner">
                    <div className="container">
                        <div className="row cyber_team">
                            
                            <div className="col-md-12 col-xs-12 col-sm-12 carousel-teams">
                                <div className="details">
                                    <div className="image">
                                        <img alt="GCC India Operations Team" src={finance} />
                                    </div>
                                    <div className="details-main">
                                        <h3>The BrewDat Team</h3>
                                        <p>BrewDat is AB InBev's official data platform, revolutionizing data-driven decision-making. It empowers teams across functions, enabling them to collect, store, process, and manage data seamlessly. With transformed ready-to-use datasets, it simplifies analytics projects. It allows data scientists to experiment with models and visualize results using the reporting tool. </p>
                                        <p>As the BrewDat team, we optimize resources to provide a comprehensive toolbox with engineer-friendly templates in a ready-to-use cloud environment. Our infrastructure strictly adheres to the company's security policies, ensuring compliance and maintaining the highest level of data security. Within the platform ecosystem, our team is actively involved in diverse areas, each with its specific responsibilities concerning data management and platform development. </p>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Finance;