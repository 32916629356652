import React from "react";
import Slider from "react-slick";
import budwsier_manum from '../../assets/images/our_brands/budwsier_manum.png';
import becks from '../../assets/images/our_brands/becks.png';
import haywards from '../../assets/images/our_brands/haywards.png';
import kockout from '../../assets/images/our_brands/kockout.png';
import corona from '../../assets/images/our_brands/corona.png';
import Goose from '../../assets/images/our_brands/Goose-IPA-1.png';
import Stella from '../../assets/images/our_brands/Stella.png';
import Leffe from '../../assets/images/our_brands/Leffe.png';
import HOEg from '../../assets/images/our_brands/HOEg.png';
import Lowenbrau from '../../assets/images/our_brands/Lowenbrau.png';
import Negra from '../../assets/images/our_brands/Negra-Modelo-1.png';
import Spanten from '../../assets/images/our_brands/Spanten.png';
import Becksnon from '../../assets/images/our_brands/Becks-non-A.png';

class IndianBrands extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div class="beers-brands">
                <div class="our-brands-content" style={{background: "#fff"}}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12">
                            <p style={{color: "#000"}}>With well over 500 brands and an extensive selection of beer varieties, we take immense pride in every ounce we brew. Discover the wide range of AB InBev brands available in Israel, offering everything from cherished classics to historic recipes.</p>

                        </div>
                    </div>
                </div>
            </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-1 col-xs-12 col-sm-1">
                        <h3 class="rotate-text">GLOBAL&nbsp;BRANDS</h3>
                        </div>
                        <div class="col-md-11 col-xs-12 col-sm-11">
                            <div class="beers-list indian-banner">
                                <Slider {...settings}>
                                <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={budwsier_manum} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Budweiser</h3>
                                                                <div>
                                                                <a href="https://www.budweiser.com" target="blank" tabindex="0">www.budweiser.com</a>
                                                                </div>
                                                                <p>The American-style lager was introduced in 1876 when company founder Adolphus Busch set out to create the United States' first truly national beer brand. Each batch of Budweiser stays true to the same family recipe used by five generations of Busch family brewmasters.</p>
                                                                <p>Budweiser is a medium-bodied, flavorful, crisp, and pure beer with blended layers of premium American and European hop aromas, brewed for the perfect balance of flavor and refreshment.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={corona} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                            <h3>Corona</h3>
                                                                <div>
                                                                <a href="https://www.corona.com" target="blank" tabindex="0">www.corona.com</a>
                                                                </div>
                                                                <p>Corona, a Mexican lager, is accentuated by slightly malty notes and fruity aromas from the fermentation process. It is balanced by a noticeable body that is light enough to quench thirst and bitter enough to deliver a desirable, crisp finish.</p>
                                                                <p>It is the most well-known and popular Mexican beer around the globe. Produced entirely in Mexico, Corona is exported to 180 countries worldwide.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Goose} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Goose Island</h3>
                                                                <div>
                                                                <a href="www.gooseisland.com" target="blank" tabindex="0">www.gooseisland.com</a>
                                                                </div>
                                                                <p>Founded in 1988, Goose Island is a leading craft brewery in the Midwest. Inspired by European beer styles, founder John Hall aimed to bring exceptional brews to America. Settling in Chicago, he embraced the city's evolving tastes and ample fresh water. In 1992, Goose Island established a renowned barrel program. Today, their state-of-the-art facility produces world-class barrel-aged beers.</p>
                                                                <p>Goose Island’s finest creations, Matilda and Sofie, are now available in Israel.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Stella} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                            <h3>Stella Artois</h3>
                                                                <div>
                                                                <a href="www.stellaartois.com" target="blank" tabindex="0">www.stellaartois.com</a>
                                                                </div>
                                                                <p>Stella Artois was initially crafted as a Christmas present for the residents of Leuven, Belgium. The beer's name holds significance, with "Stella" meaning "Star" in Latin and "Artois" the surname of its talented brewmaster, Sebastian Artois. Known for its pleasantly bitter and refreshing taste, Stella Artois boasts a crisp finish that leaves a lasting impression.</p>
                                                                <p>Stella Artois' crisp, refreshing flavor pairs well with intensely flavored Thai, Asian Fusion, and Indian dishes.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Leffe} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                            <h3>Leffe</h3>
                                                                <div>
                                                                <a href="www.leffe.com" target="blank" tabindex="0">www.leffe.com</a>
                                                                </div>
                                                                <p>Leffe, an ale brewed 800 years ago by monks in Leffe Abbey, Dinant, Belgium, provided a safer alternative to water during a time of plague.</p>
                                                                <p>Today, Leffe is brewed in Leuven, and all Leffe variants are fermented with the same unique yeast, offering harmony with notes of vanilla, spicy clove/smoke, banana, and ripe fruits. Distinct ingredients like biscuit malts, roasted malts, and red fruits enhance the signature character of each variant.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={HOEg} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                            <h3>Hoegaarden</h3>
                                                                <div>
                                                                <a href="www.hoegaarden.com" target="blank" tabindex="0">www.hoegaarden.com</a>
                                                                </div>
                                                                <p>Hoegaarden pronounced who-gar-den, traces its origins back to 1445 to a small Belgian village where dedicated monks crafted a unique wheat beer. Through meticulous experimentation, the monks discovered the perfect blend of orange peel and coriander.</p>
                                                                <p>Hoegaarden has gained popularity and garnered numerous prestigious awards for its exceptional quality and taste. This naturally unfiltered beer has refreshing fruity, floral, and citrus flavors. It boasts a bright and easy-to-drink profile.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Lowenbrau} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Löwenbräu</h3>
                                                                <div>
                                                                    <a href="www.loewenbraeu.de" target="blank" tabindex="0">www.loewenbraeu.de</a>
                                                                </div>
                                                                <p>Löwenbräu, a renowned German brewery, has its origins dating back to 1383 in Munich. Today, it stands as a prominent symbol of Bavarian beer culture, adhering to the strict standards of the German Purity Law.</p>
                                                                <p>Löwenbräu's flagship lager boasts a clean and crisp taste, showcasing the brewery's commitment to quality. Its rich golden color and balanced maltiness pair excellently with traditional German cuisines like bratwurst, sauerkraut, and pretzels.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Negra} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Modelo Negra</h3>
                                                                <div>
                                                                    <a href="www.modelousa.com" target="blank" tabindex="0">www.modelousa.com</a>
                                                                </div>
                                                                <p>Modelo Negra is a Dunkel-style lager introduced in the 1920s by Austrian immigrants in Mexico. It has a bright, rich, amber hue and is sold in a uniquely shaped bottle.</p>
                                                                <p>This medium-bodied lager features slow-roasted caramel malts that deliver a rich flavor and remarkably smooth taste. Brewed longer to enhance flavors, Modelo Negra pairs well with traditional Mexican dishes, making it a delightful choice to complement any recipe.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Spanten} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Spaten</h3>
                                                                <div>
                                                                    <a href="www.spatenbraeu.de" target="blank" tabindex="0">www.spatenbraeu.de</a>
                                                                </div>
                                                                <p>Spaten, a renowned German brewery with a rich history dating back to 1397, continues to produce exceptional beers today. Known for its commitment to traditional brewing methods, Spaten offers a range of high-quality brews.</p>
                                                                <p>Their flagship beer, Spaten Lager, is a classic German lager with a balanced and crisp taste. It pairs well with hearty Bavarian dishes such as sausages, pretzels, and roasted meats, complementing their flavors with its refreshing character.</p>
                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Becksnon} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Beck’s</h3>
                                                                <div>
                                                                    <a href="www.becks.de" target="blank" tabindex="0">www.becks.de</a>
                                                                </div>
                                                                <p>Beck's, a renowned German brand, is the top-selling German beer globally, loved in 80+ countries. Known for its innovation, Beck's introduced green bottles when brown glass was scarce, pioneering this packaging trend.</p>
                                                                <p>Brewed as an authentic German pilsner, Beck's adheres to the German Beer Purity Law, using four natural ingredients—Barley, Hops, Water, and Yeast—for its unique aroma, bitterness, and hoppy aftertaste.</p>
                                                                <p>Beck's 0.0 is also available in Israel.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default IndianBrands;