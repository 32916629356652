import React from "react";

class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner diversity-banner">
                <div className="common-banner-inner">
                    <h1> <div className="yel-col">An Equitable Future. An Inclusive Future.</div> <span className="red-text"> An Empowered Future.</span> </h1>
                </div>
            </section>
        );
    }
}
export default Banner;