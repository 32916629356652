import React from "react";
import Slider from "react-slick";
import Budweiser_Card_new from '../../assets/images/our_brands/Budweiser_Card_new.png';
import USA_Flag from '../../assets/images/our_brands/USA_Flag.svg';
import Corona_Card_new from '../../assets/images/our_brands/Corona_Card_new.png';
import corona_extra from '../../assets/images/our_brands/corona_extra.png';
import Hoegaarden_home from '../../assets/images/our_brands/Hoegaarden_home.png';
import heogorgen from '../../assets/images/our_brands/heogorgen.png';
import { Link } from "react-router-dom";
class GlobalBrands extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div class="beers-brands">
                <div class="container">
                    <div class="row">
                        <div class="col-md-1 col-xs-12 col-sm-1">
                            <h3 class="rotate-text">GLOBAL&nbsp;BRANDS</h3>
                        </div>
                        <div class="col-md-11 col-xs-12 col-sm-11">
                            <div class="beers-list global-banner">
                                <Slider {...settings}>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Budweiser_Card_new} />
                                                        </div>
                                                        <div class="flag">
                                                            <img src={USA_Flag} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Budweiser</h3>
                                                                <div>
                                                                    <Link to="https://www.budweiser.com" target="blank" tabindex="0">www.budweiser.com</Link>
                                                                </div>
                                                                <p>The American-style lager was introduced in 1876 when company founder Adolphus Busch set out to create the United States' first truly national beer brand. Each batch of Budweiser stays true to the same family recipe used by five generations of Busch family brewmasters.</p>
                                                                <p>Budweiser is a medium-bodied, flavorful, crisp, and pure beer with blended layers of premium American and European hop aromas, brewed for the perfect balance of flavor and refreshment.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Corona_Card_new} />
                                                        </div>
                                                        <div class="flag">
                                                            <img src={corona_extra} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Corona</h3>
                                                                <div>
                                                                    <Link to="https://www.corona.com" target="blank" tabindex="0">www.corona.com</Link>
                                                                </div>
                                                                <p>Corona, a Mexican lager, is accentuated by slightly malty notes and fruity aromas from the fermentation process. It is balanced by a noticeable body that is light enough to quench thirst and bitter enough to deliver a desirable, crisp finish.</p>
                                                                <p>It is the most well-known and popular Mexican beer around the globe. Produced entirely in Mexico, Corona is exported to 180 countries worldwide.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={Hoegaarden_home} />
                                                        </div>
                                                        <div class="flag">
                                                            <img src={heogorgen} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Goose Island</h3>
                                                                <div>
                                                                    <Link to="www.gooseisland.com" target="blank" tabindex="0">www.gooseisland.com</Link>
                                                                </div>
                                                                <p>Founded in 1988, Goose Island is a leading craft brewery in the Midwest. Inspired by European beer styles, founder John Hall aimed to bring exceptional brews to America. Settling in Chicago, he embraced the city's evolving tastes and ample fresh water. In 1992, Goose Island established a renowned barrel program. Today, their state-of-the-art facility produces world-class barrel-aged beers.</p>
                                                                <p>Goose Island’s finest creations, Matilda and Sofie, are now available in Israel.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default GlobalBrands;