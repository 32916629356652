import React, {useState} from "react";
import avi from '../../../assets/images/GCC_Leaders/avi.png';
import Modal from 'react-bootstrap/Modal';
class Runa extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Runa Dhawan" src={avi} />
                    </div>
                    <div class="details">
                        <h4>Avi Kreimer</h4>
                        <p>Partnerships & Data Products Director</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="avi Dhawan" src={avi} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Avi Kreimer</h4>
                            <p class="sub-head">Partnerships & Data Products Director</p>
                            <p class="content">Avi, currently a part of BrewDat at AB InBev, leads Data Products development and Strategic Partnerships, collaborating with functions and zones worldwide. He joined the company in 2016 with a focus on scaling up tech initiatives in sales and logistics. Afterward, he transitioned to the Tech Innovation team, where he successfully built the team in Israel.</p>
                           <div className="bottom_text_cls">
                           <p class="content">Prior to joining AB InBev, Avi had a diverse background in technical roles within Israeli startups. Seeking to enhance his skill set, he pursued an MBA at Kellogg School of Management in the US, acquiring a solid foundation in business management and strategy. Subsequently, he embarked on a consulting career at Ernst & Young, specializing in Operations and Strategy and providing valuable support to Fortune 500 clients in their M&A endeavors.</p>
                           </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default Runa;