import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import madhur_batia from '../../../assets/images/GCC_Leaders/madhur_batia.png';
class MadhurBhatia extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Madhur Bhatia" src={madhur_batia} />
                    </div>
                    <div class="details">
                        <h4>Madhur Bhatia</h4>
                        <p>Director, Analytics</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={madhur_batia} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Madhur Bhatia</h4>
                            <p class="sub-head">Director, Analytics</p>
                            <p class="content">Madhur Bhatia is a passionate beer lover currently serving as Director - Analytics at AB InBev’s centre of excellence for analytics in Bangalore, India. Madhur holds a Bachelor's in Computer Science and a Master's in Management. His 5-year journey with AB InBev has taken him from managing data science projects for Revenue Management & TechSales to leading a large product and research team managing data science & research initiatives for People, Procurement, and Finance domains. Madhur finds inspiration in collaborating with motivated individuals and solving problems never solved before. He also manages a YouTube channel with over 100,000 subscribers where he shares tutorials on scripting, database administration & web development. In his free time Madhur enjoys playing guitar, reading, and training Machine Learning algorithms for stock trading.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default MadhurBhatia;