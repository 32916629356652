import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner sustaibility-banner">
                <div className="common-banner-inner">
                    <h1> <div className="yel-col">How We're Helping</div><span className="red-text">Change the World</span></h1>
                </div>
            </section>
        );
    }
}
export default Banner;