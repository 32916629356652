import React from "react";
import di_5_5 from '../../assets/images/diversity_inclusion/di_5_5.png';
import di_2_2 from '../../assets/images/diversity_inclusion/di_2_2.png';
import di_1_1_1 from '../../assets/images/diversity_inclusion/di_1_1_1.png';
import di_4_4 from '../../assets/images/diversity_inclusion/di_4_4.png';
import Anat_Eiar from '../../assets/images/diversity_inclusion/Anat_Eiar.png';


class SafeWorkPlace extends React.Component {
    render() {
        return(
            <div className="diversity-inclusion-equal stack_cls">
                <div className="container">
                    <div className="row">
                    <div className="col-md-3 col-xs-3 col-sm-3 anat_eiar">
                        <img src={Anat_Eiar} />
                    </div>
                        <div className="col-md-9 col-xs-9 col-sm-9">
                            <h2 className="color_white">Our Unwavering  <span>Commitment to DEI</span> </h2>
                            <p style={{textAlign:'left'}}>
                         " We're committed to a world where everyone feels valued. Embracing diversity, promoting equity, and fostering inclusion not only aligns with our values but also fuels innovation, growth, and harmony. By valuing diverse perspectives and building trust, we create an inclusive community where everyone can contribute their best. This leads to more collaboration, creativity, and productivity, driving our organization's success and benefiting our communities, colleagues, and stakeholders."
                            </p>
                            <div className="user_cls_sts">
                            <span className="text_left"> <b>Anat & Eiar</b> </span>
                           <br/> <span className="text_left"><b>The DEI Council</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SafeWorkPlace;