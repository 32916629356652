import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner">
                <div className="common-banner-inner">
                <p style={{ color: '#e6b611'}}>DREAM. PEOPLE. CULTURE.</p>
                    <h1>Our 10<span className="red-text"> Principles</span></h1>
                </div>
            </section>
        );
    }
}
export default Banner;