import React from "react";
class Goals extends React.Component {
    render() {
        return(
            <div className="history-sustainability">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 text-center">
                            <h4>Cheers to the remarkable 600 years that have passed.</h4>
                            <h2>And here’s to 100 more!</h2>
                            {/* <a href="/sustainability">View 2025 Goals</a> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Goals;