import React from "react";
import sustaibility_icon_1 from '../../assets/images/sustainability/sustainability_1.png';
import sustaibility_icon_2 from '../../assets/images/sustainability/sustainability_2.png';
import sustaibility_icon_3 from '../../assets/images/sustainability/sustainability_3.png';
import sustaibility_icon_4 from '../../assets/images/sustainability/sustainability_4.png';

class SustainabilityList extends React.Component {
    render() {
        return(
            <div className="sustainability-list">
                {/* <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12 text-center">
                            <p className="content">Brewing quality beer starts with the best ingredients. This requires a healthy, natural environment, as well as thriving communities. We are building a company to last, bringing people together for a Better World, now and for the next 100+ years. That’s why sustainability isn’t just part of our business, it is our business. </p>
                            <p className="content">We were recognized as one of only 37 Global Compact LEAD companies for our ongoing commitment to the UN Global Compact, its Ten Principles for responsible business, and the related Sustainable Development Goals.  </p>
                            <p className="content">In line with our commitment to Smart Agriculture, we continue to innovate to support farmers around the world to be skilled, connected, and financially empowered, like our recent expansion of the blockchain-enabled supply. </p>
                            <ul className="sustainability-list">
                                <li><img alt="Water Stewardship" src={sustaibility_icon_1} /></li>
                                <li><img alt="Smart Agriculture" src={sustaibility_icon_1} /></li>
                                <li className="hidden-xs"><img alt="Climate Action" src={sustaibility_icon_3} /></li>
                                <li className="hidden-xs"><img alt="Circular Packaging" src={sustaibility_icon_4} /></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}
export default SustainabilityList;