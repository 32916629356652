import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import alexKomrakov from '../../../assets/images/GCC_Leaders/alexKomrakov.png';
class AlexanderKomrakov extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Alexander Komrakov" src={alexKomrakov} />
                    </div>
                    <div class="details">
                        <h4>Alex Mor</h4>
                        <p>Global Director, Application Security</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={alexKomrakov} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Alex Mor</h4>
                            <p class="sub-head">Global Director, Application Security</p>
                            <p class="content">Alex is a seasoned cybersecurity professional with over 15 years of hands-on experience in the field. He is passionate about security and adeptly assumes both the builder and breaker roles, depending on the time of day.</p>
                            <p class="content">As the head of AB InBev's enterprise application security program, Alex provides expert technical guidance and conducts security awareness training for engineering teams. His primary focus is ensuring the platform's security and robustness throughout the development process.</p>
                           <div className="bottom_text_cls">
                           <p class="content">With extensive expertise in designing, implementing, and managing vulnerability assessment and penetration testing programs, Alex is also responsible for evaluating and selecting application security tools to minimize enterprise risk. This involves conducting gap analysis, defining requirements, performing market research, prototyping, and optimizing workflows and processes.</p>
                           </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default AlexanderKomrakov;