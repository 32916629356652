import React from "react";
import teams from '../../assets/images/team_new_mobile.png';
import { Link } from "react-router-dom";
class Teams extends React.Component {
    render() {
        return(
            <div class="col-md-4 col-xs-12 col-sm-4">
                <div class="benefits-list">
                    <div class="image">
                        <img src={teams} width="100%" alt="OUR Better World Initiatives" />
                    </div>
                    <div class="details">
                        <h2>OUR TEAM</h2>
                        <p>With hard work and the strength of our teams, we can do anything.</p>
                        <Link to="/cybersecurity">
                            <span>EXPLORE OUR TEAM</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
export default Teams;