import React from "react";
import benefits from '../../assets/images/benefits_new_mobile.png';
class Benefits extends React.Component {
    render() {
        return(
            <div class="col-md-4 col-xs-12 col-sm-4">
                <div class="benefits-list">
                    <div class="image">
                        <img src={benefits} width="100%" alt="OUR BENEFITS" />
                    </div>
                    <div class="details">
                        <h2>OUR BENEFITS</h2>
                        <p>Our company was built on ownership, so we reward talent and ambition.</p>
                        <a href="/gcc-benefits">
                            <span>EXPLORE BENEFTIS</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Benefits;