import React from "react";
import Teams from "../BUViewMore/Teams";
import Location from "../BUViewMore/Location";
import Benefits from "../BUViewMore/Benefits";
class ViewMore extends React.Component {
    render() {
        return(
            <div class="our-entities our-graduate-programs list-btm-box">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12">
                            <div class="heading-bu">
                                <h3 class="more-about">More About <span>BU India</span></h3>
                            </div>
                        </div>
                        <Benefits />
                        <Teams />
                        <Location />
                    </div>
                </div>
            </div>
        );
    }
}
export default ViewMore;