import React from "react";
import we_are_brewers from '../../assets/images/home/home-we-are.png';
import { Link } from "react-router-dom";
class WeAreBrewers extends React.Component {
    render() {
        return(
            <div className="we-are-brewers">
                <div className="container">
                    <div className="row">
                    <div className="col-md-5 col-xs-12 col-sm-5">
                            <div className="image">
                                <img src={we_are_brewers} alt=" We are brewers" />
                            </div>
                        </div>
                        <div className="col-md-7 col-xs-12 col-sm-7">
                            <div className="we-are-brewers-content">
                                <div className="strip"></div>
                                <h4>We Are Brewers!</h4>
                                <p>Our purpose is to Dream Big to create a future with more cheers – a future where everyone comes together to raise a toast; where we Dream Big to serve up new ways of celebrating life’s moments, move our industry forward, and make a meaningful impact on our community and the world.</p>
                            </div>
                        </div>
                        
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="history-abinbev">
                                <p>The history of AB InBev is a global story that spans continents and generations. It’s not just our history, it’s the history of beer itself.</p>
                                <Link className="common-link" to="/our-history">Know more about our history</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WeAreBrewers;