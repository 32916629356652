import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import kartikey from '../../../assets/images/BU_Leaders/kartikeya.png';
class KartikeyaSharma extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Alexander Komrakov" src={kartikey} />
                    </div>
                    <div class="details">
                        <h4>Kartikeya Sharma</h4>
                        <p>President, India & South East Asia</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={kartikey} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Kartikeya Sharma</h4>
                            <p class="sub-head">President, India & South East Asia</p>
                            <p class="content">Kartikeya started his journey with Anheuser Busch InBev in 2005 and currently heads both the India and South East Asia Business for the company. Kartikeya has held various key positions within the company including Business Development for new markets – East and West Africa based out of Leuven, Belgium, Brand Manager – Global & Local Portfolios here in India, Vice President Marketing for South Asia and Vice President Sales here in India.<br/><br/>With over 15 years of experience in marketing, sales and business development, Kartikeya has led business development in Africa, Brand management of Corona at Labatt Breweries in Canada. Kartikeya was also part of the team that set up the India business via a Joint Venture, where he managed both Marketing and Trade Marketing. As VP Marketing and later as VP Sales, Kartikeya played an integral role in driving sustainable portfolio performance and profitable growth while leading the team to achieve category-leading brand equity for Budweiser and Budweiser Magnum. In his most recent role as President – South Asia, Kartikeya has steered several transformational initiatives to strengthen the company’s business in the region by leading commercial teams to achieve category-leading share growth, foraying into the craft beer space through an industry-first partnership with Indian Hotels Company Limited (IHCL) and strengthening the company’s No and Low Alcohol Beverage portfolio with Budweiser 0.0, Hoegaarden 0.0 and Beck’s Ice Premium Lager 3.5% ABV super mild beer. He was also instrumental in expanding the brewing capabilities of global brands – Corona and Hoegaarden here in India.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default KartikeyaSharma;