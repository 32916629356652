import React from "react";
import creche from '../../assets/images/diversity_inclusion/creche.png';
import ParentalLeave from '../../assets/images/diversity_inclusion/ParentalLeave.png';
import carenine_program from '../../assets/images/diversity_inclusion/carenine_program.png';


class ParentalPolicies extends React.Component {
    render() {
        return(
            <div className="parental-policy-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="heading-bu">
                                <h3 className="more-about">Parental <span>Policies</span></h3>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details next-list">
                                <div className="image">
                                    <img alt="Extended Maternity Leave Policy" src={creche} width="100%" />
                                </div>
                                <div className="details">
                                    <h2>Paid Maternity Leave</h2>
                                    <p>Mothers and primary caregivers, including adoptive parents, receive 26 weeks of fully paid leave.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details next-list">
                                <div className="image">
                                    <img alt="Parental Support Program" src={ParentalLeave} width="100%" />
                                </div>
                                <div className="details">
                                    <h2>Return to Work</h2>
                                    <p>As part of our Ramp On program for new parents, mothers and primary caregivers can work 75% of their usual hours with full pay for the first 8 weeks upon return.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-4">
                            <div className="program-details next-list">
                                <div className="image">
                                    <img alt="Lactating Rooms" src={carenine_program} width="100%" />
                                </div>
                                <div className="details">
                                    <h2>Paid Paternity Leave</h2>
                                    <p>Fathers and secondary caregivers, including adoptive parents, receive 4 weeks of fully paid leave.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ParentalPolicies;