import React from "react";
import { Link } from "react-router-dom";
import  Strategic_Leadership from '../../assets/images/programmes/Solving.png';
import Team_Empowerment from '../../assets/images/programmes/Industry.png';
import Inclusive_Culture from '../../assets/images/programmes/Substantial.png';
import Adaptive_Leadership from '../../assets/images/programmes/Benefivial.png';
class MainContent extends React.Component {
    render() {
        return(
            <div>
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                <p>At AB InBev, we have partnered with TAU Ventures (Tel Aviv University's VC) and the IIA (Israel Innovation Authority) for the ReCombinate program. This initiative is designed to empower innovative Israeli startups with versatile technologies capable of addressing our present and future business challenges. </p>
                                <p>The program has two main objectives: piloting use-case-ready solutions and generating innovative solutions using proprietary technology to meet business needs. </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                                <h3>What to Expect</h3>
                                <p>Successful Israeli applicants will receive funding from the IIA, comprehensive support along the process from TAU Ventures, and in-kind services from AB InBev. Participating startups will also gain access to extensive guidance and benefits from the program’s supporting partners, including KPMG, LeumiTech, Barnea, and Microsoft for Startups. </p>
                                <p>Selected startups will create and execute comprehensive R&D or pilot plans grounded in real-world needs and industry insights provided by AB InBev. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content key_takeaways bottom_tow">
                    <div className="container">
                    <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <h3>Key Takeaways</h3>
                        <ul>
                                    <li>
                                    <img src={Strategic_Leadership}/>
                                        <b>Solving Real Challenges</b> Address problems in critical areas such as Water Management, In-Brewery Operations, Agriculture, Procurement, Logistics, and more.
                                    </li>
                                    <li>
                                    <img src={Team_Empowerment}/>
                                        <b>Industry Insights</b> Gain invaluable industry knowledge to develop solutions addressing real business needs. 
                                    </li>
                                    <li>
                                    <img src={Inclusive_Culture}/>
                                        <b>Substantial De-Risking </b> Minimize risks and costs related to R&D, piloting, and integration with tech validation from the IIA and support from experienced VC partners. 
                                    </li>
                                    <li>
                                    <img src={Adaptive_Leadership}/>
                                        <b>Beneficial Collaborative Outcomes</b> These may include procurement, joint ventures, co-selling agreements, or technology licensing with AB InBev. 
                                    </li>
                                    
                                </ul>
                                </div>
                                </div>
                    </div>
                </div>
                <div class="common-programme-content apply_now_cls">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                            <p>Are you an Israeli startup with an innovative solution? Seize the opportunity!</p>
                            <Link className="common-link" to="">Apply now!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainContent;