import React from "react";
import { NavLink , useLocation,Link} from "react-router-dom";
import {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from 'react-bootstrap/NavDropdown';
import logo_age from "../../../assets/images/Logo-Yellow-White.png";


function Header2() {

    // const{pathname}= useLocation();
    // useEffect(()=>{
    //   window.scrollTo(0,0);
    // },[pathname]);

    const [count, setCount] = useState('/our-history');
    const [home, setHome] = useState('myclass');
    const [who, setwho] = useState('myclass');
    const [what, setwhat] = useState('myclass');
    const [leaders, setleaders] = useState('myclass');
    const [prog, setprog] = useState('myclass');
    const [brands, setbrands] = useState('myclass');
    const [career, setcareer] = useState('myclass');
    const location = useLocation();
    // console.log('location',location.pathname)
    useEffect(() => {
      window.scrollTo(0,0);
      if (location.pathname == '/home'){
        setHome('myclass-acctive');
        // console.log('came')
      }else if (location.pathname == '/our-values' || location.pathname == '/sustainability'|| location.pathname == '/diversity-inclusion'){
        setwho('myclass-acctive');
      }else if (location.pathname == '/cybersecurity' || location.pathname == '/brewDat' || location.pathname == '/innovation') {
        setwhat('myclass-acctive');
      }else if (location.pathname == '/tech-master' || location.pathname == '/managing' || location.pathname=='/ReCombinate') {
        setprog('myclass-acctive');
      }
    }, [location.pathname]);
    
    
    return (
        <header>
          <Navbar fixed="top" className="header-menu" expand="lg">
            <Container>
              <Navbar.Brand>
                <Link to="/home" className="logo_cls">
                  <img width="100" src={logo_age} alt="logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="menu-links" id="basic-navbar-nav">
                <Nav className="">
                  <div className="hover-menu">
                    <div className="main-link-hover">
                      <NavLink to="/home" className={home}>
                        Home
                      </NavLink>
                    </div>
                  </div>
  
                  <div className="hover-menu">
                    <div className="main-link-hover">
                      <NavLink to="/our-history" className={who} >
                        Who We Are
                      </NavLink>
                    </div>
                    <div className="sub-menu">
                      <NavLink  to="/our-history" >History</NavLink>
                      <NavLink  to="/our-values" >Principles</NavLink>
                      <NavLink to="/sustainability" >Sustainability Goals</NavLink>
                      <NavLink to="/diversity-inclusion" >DEI</NavLink>
                      {/* <NavLink to="/diversity-inclusion">Diversity, Equity & Inclusion </NavLink> */}
                    </div>
                  </div>
                  <div className="hover-menu">
                    <div className="main-link-hover">
                      <NavLink to="/cybersecurity"  className={what} >
                        What We Do
                      </NavLink>
                    </div>
                    <div className="sub-menu">
                      <NavLink to="/cybersecurity" >Cybersecurity</NavLink>
                      <NavLink to="/brewDat" >BrewDat</NavLink>
                      <NavLink to="/innovation" >Innovation</NavLink>
                    </div>
                  </div>
                  <div className="hover-menu">
                    <div className="main-link-hover">
                      <NavLink to="/leaders" >Leaders</NavLink>
                    </div>
                    {/* <div className="sub-menu">
                                  <NavLink to="/leaders">GCC Leaders</NavLink>
                                  <NavLink to="/bu-leaders">BU Leaders</NavLink>
                              </div> */}
                  </div>
                  <div className="hover-menu our-leaders-menu">
                    <div className="main-link-hover">
                      <NavLink to="/lean-in-circle" className={prog}>Programs</NavLink>
                    </div>
                    <div className="sub-menu">
                      <NavLink to="/lean-in-circle" >
                      Lean In Circle
                      </NavLink>
                      <NavLink to="/tech-master" >AB InBev Tech Master</NavLink>
                      <NavLink to="/managing" >Managing@ABI</NavLink>
                      <NavLink to="/ReCombinate" >ReCombinate</NavLink>
                    </div>
                  </div>
                  <div className="hover-menu">
                    <div className="main-link-hover">
                      <NavLink to="/our-brands" >Brands</NavLink>
                    </div>
                  </div>
  
                  <div className="hover-menu">
                    <div className="main-link-hover">
                      <NavLink to="https://www.comeet.com/jobs/ABInBev/54.004" target="_blank" rel="noopener noreferrer">Careers</NavLink>
                    </div>
                  </div>
  
                  {/* <NavLink to="/about">About</NavLink>
                          <NavLink to="/contact">Contact</NavLink> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {/* <NavLink to="/">Home</NavLink>   */}
          {/* <NavLink to="/home">Home</NavLink> | <NavLink to="/about">About</NavLink> | <NavLink to="/contact">Contact</NavLink> */}
        </header>
      );
}
export default Header2;
