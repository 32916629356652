import React from "react";
import icon1 from '../../assets/images/GCC_India/DataGovernance.png';
import icon2 from '../../assets/images/GCC_India/DataQuality.png';
import icon3 from '../../assets/images/GCC_India/Coreinfrastructure.png';
import icon4 from '../../assets/images/GCC_India/Operation.png';
import icon5 from '../../assets/images/GCC_India/Dataengineering.png';
import icon6 from '../../assets/images/GCC_India/Roadmap.png';
import icon7 from '../../assets/images/GCC_India/Transformation.png';
import icon8 from '../../assets/images/GCC_India/icon8.png';

class Card extends React.Component {
  render() {
    const { icon, heading, paragraph } = this.props.data;

    const cardStyle = {
      width: "100%", // Set the width to 100%
      height: "350px", // Set the desired height
      marginBottom: "90px", // Add margin at the bottom for spacing
      backgroundColor: "black", // Black background color for each card
      color: "white", // Text color
      padding: "20px", // Padding inside each card
      boxSizing: "border-box", // Include padding in the card's dimensions
      
    };

    const iconStyle = {
      height: "20px", // Set the height of the icon
      width: "20px",
     
    };

    const yellowLineStyle = {
      height: "4px", // Set the height of the yellow line
      backgroundColor: "#E5B611", // Yellow color for the line
      width: "94px", // Set the width of the line
      margin: "0", // Center the line horizontally
      marginTop: "15px", // Add margin to separate the line from the text
    };


    return (
      <div className="card">
           <img src={icon} alt={heading} className="card-icon" />
         <div style={yellowLineStyle}></div>
        <h5>{heading}</h5>
        <p style={{fontSize:"13px"}}>{paragraph}</p>
      </div>
    );
  }
}

class CardRow extends React.Component {
  render() {
    // Replace these sample data with your actual card data
    const cardData = [
      {
        icon: icon1,
        heading: "Data Governance",
        paragraph: "Responsible for policies, guidelines, and processes for data governance within the platform, the team defines standards for data usage, access, sharing, and security, ensuring compliance with regulations and internal policies. They manage user roles, metadata, and the data lifecycle.",
      },
      {
        icon: icon2,
        heading: "Data Quality",
        paragraph: "This area oversees the quality of data used in the platform. It includes defining and applying quality standards and rules. They collaborate with Transformation and Engineering teams for continuous monitoring and improvement.",
      },
      {
        icon: icon3,
        heading: "Core Infrastructure",
        paragraph: "The Core Infrastructure area provides and maintains the IT infrastructure to support the data platform. They manage servers, networks, storage, and operating systems and optimize performance, security, and scalability.",
      },
      {
        icon: icon4,
        heading: "Operations",
        paragraph: "The Operations area ensures data platform availability and functionality by proactively monitoring, resolving issues, and managing incidents. They also prioritize data security, backups, and disaster recovery.",
      },
      {
        icon: icon5,
        heading: "Data Engineering",
        paragraph: "Engineers in this area develop and maintain data platform infrastructure, tools, and APIs. They build data pipelines and monitoring apps and provide technical support to ensure scalability, security, and performance.",
      },
      {
        icon: icon6,
        heading: "Roadmap",
        paragraph: "This area is responsible for defining the strategic vision and future direction of the data platform. They help ensure the platform is aligned with business needs and company goals. ",
      },
      {
        icon: icon7,
        heading: "Transformation",
        paragraph: "The Transformation area drives software migration, innovation, and process improvements for the data platform. The team identifies new technologies, best practices, and innovative approaches to improve data processes, including legacy software migration, adopting new tools, and implementing automation for increased productivity and scalability. ",
      },
      // Add more card data as needed
    ];

    // Divide cardData into two arrays, each containing four cards
    const firstRow = cardData.slice(0, 4);
    const secondRow = cardData.slice(4, 6);
    const thirddRow = cardData.slice(6, 7);

    const cardContainerStyle = {
      // Black background for the entire group
      backgroundColor: "black",
      marginLeft: "20%",// Add left margin to the card container
      
    };

    return (
      <section className="cyber_sequ" style={{ backgroundColor: "black" }}>
    <div className="container">
      <div className="row">
        <div className="col-md-1 col-xs-12 col-sm-1"style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h2 className="head-rotate">BREWDAT</h2>
        </div>
        <div class="col-md-11 col-sm-11" style={{marginTop:'35px'}}>
        <h2 style={{ color: "white"}}>Areas of Focus</h2>
          <div className="row row1">
            {firstRow.map((card, index) => (
              <div className="col-md-3" key={index}>
                <Card data={card} />
              </div>
            ))}
          </div>
          <div className="row row2">
            {secondRow.map((card, index) => (
              <div className="col-md-3" key={index}>
                <Card data={card} />
              </div>
            ))}
            {thirddRow.map((card, index) => (
              <div className="col-md-6" key={index}>
                <Card data={card} />
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
      </section>
    );
  }
}

export default CardRow;
