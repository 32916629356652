import React from "react";
import project_sabala from '../../assets/images/BUBetterWorldInitiatives/project_sabala.jpeg';
import smart_agriculture from '../../assets/images/BUBetterWorldInitiatives/smart_agriculture.jpeg';
import water_stewardship from '../../assets/images/BUBetterWorldInitiatives/water_stewardship.jpeg';
import climate_action from '../../assets/images/BUBetterWorldInitiatives/climate_action.png';
class BUList extends React.Component {
    render() {
        return(
            <div class="our-entities our-graduate-programs our-ini-bu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12">
                            <h3 data-aos="fade-up" class="aos-init aos-animate">OUR <span>BETTER WORLD</span> INITIATIVES </h3>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-6">
                            <div class="program-details">
                                <div class="image">
                                    <img src={project_sabala} alt="Project Sabala" />
                                </div>
                                <div class="details">
                                    <h4>Project Sabala</h4>
                                    <p>Project Sabala aims to uplift women from marginalised sections of society. Project Sabala (meaning “empowered”) aims to identify, nurture, and enable enterprising women from rural clusters around our breweries and transform them into digitally and financially literate micro-entrepreneurs. <br /> The project is currently being implemented in Telangana and Haryana with plans of national expansion. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-6">
                            <div class="program-details">
                                <div class="image">
                                    <img src={smart_agriculture} alt="Smart Agriculture" />
                                </div>
                                <div class="details">
                                    <h4>Smart Agriculture</h4>
                                    <p>In India, as part of our Smart Agriculture goal, we have worked with 1200+ direct farmers through 11000+ unique visits through the growing season. We have helped them grow top quality barley which was then procured by us in ~99% within defined quality specifications! <br /> Brewing our beers is reliant on a healthy, natural environment, as well as on thriving communities. That is why we strive for a world where natural resources are preserved for the future. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-6">
                            <div class="program-details">
                                <div class="image">
                                    <img src={water_stewardship} alt="Water Stewardship" />
                                </div>
                                <div class="details">
                                    <h4>Water Stewardship</h4>
                                    <p>As part of our Water Stewardship Sustainability Goal, in India, we recently identified 5 high-risk watershed aquifers around our breweries. We focused on underserved water communities in select 25+ villages in these aquifers. As a part of the solution, we have now created cumulative recharge potential of 1 billion litres with 15 million litres of drinking water dispensed through water ATMs, providing water security to more than 45K people. <br />
                                        <br /> We're supporting over 35,000 direct farmers across 13 countries and 5 continents, with access to tech, skills, and finance, to help them grow the best ingredients for our beer.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-6">
                            <div class="program-details">
                                <div class="image">
                                    <img src={climate_action} alt="Climate Action" />
                                </div>
                                <div class="details">
                                    <h4>Climate Action</h4>
                                    <p>Through our Climate Action Sustainability goal, we now have electricity needs at our breweries near Mysore, Aurangabad and Hyderabad being addressed through renewable means (70%+ and 15%+ respectively). Additionally,100% forklifts in our breweries are electric. We plan to expand RE Procurement into three more of our high-consumption plant locations. <br />
                                        <br /> Through our global Circular Packaging initiative, we aim to make 100% of our packaging material either from recycled content or in the form of returnable materials by 2025. Extending this to India, 98% glass bottles are returnable, new glass bottles have 36% recycled content and our cans have ~ 65% recycled content. Our cans are made up of approximately 65% of recycled material, and 98% of our glass bottles are returnable.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BUList;