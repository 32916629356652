import React from "react";
class BrewingHistory extends React.Component {
    render() {
        return(
            <div className="common-heading-sub">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <p>Our rich tradition of crafting exceptional beers has withstood the test of time. Our journey originated in Belgium, from where we went on to offer the most refreshing brews to the rest of the world.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BrewingHistory;