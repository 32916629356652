import React from "react";
class Heading extends React.Component {
    render() {
        return(
            <div className="common-heading-sub">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <p>Explore the remarkable professional journeys of our leaders who are not only making history in their respective fields but also encouraging their teams to dream big. They are fostering a culture of innovation, collaboration, and forging a legacy that inspires generations to come.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Heading;