import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import prafulla from '../../../assets/images/GCC_Leaders/prafulla.png';
class PrafullaSomani extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Prafulla Somani" src={prafulla} />
                    </div>
                    <div class="details">
                        <h4>Carmel Raskin</h4>
                        <p>Global Director of Innovation</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={prafulla} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Carmel Raskin</h4>
                            <p class="sub-head">Global Director of Innovation</p>
                            <p class="content">Carmel leads Beer Garage, AB InBev's open Innovation arm. This division is responsible for tackling present challenges and shaping future solutions by identifying and validating innovative technology capabilities through our ecosystem partners.</p>
                            <div className="bottom_text_cls">
                            <p class="content">With an MBA from the Massachusetts Institute of Technology (MIT), Carmel joined AB InBev in 2019, taking charge of innovation and expansion initiatives for Japan, New Zealand, and the Pacific Islands (JNZ). She launched the first imported seltzer and non-alcoholic beer in Japan during her tenure. Additionally, Carmel established the commercial capabilities function in JNZ, which encompassed Tech Sales, Revenue Management, and Commercial Intelligence.</p>
                            <p class="content">Beyond her professional accomplishments, Carmel finds joy in being a parent to her children and indulges in her passion for snowboarding during her free time.</p>
                            </div>
                            
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default PrafullaSomani;