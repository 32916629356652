import React from 'react';
import Analytics from '../components/GCCIndia/Analytics';
import Awards from '../components/GCCIndia/Awards';
import Banner from '../components/GCCIndia/Banner';
import Heading from '../components/GCCIndia/Heading';
import MoreAbout from '../components/Common/MoreAbout';
import Operations from '../components/GCCIndia/Operations';
import Tech from '../components/GCCIndia/Tech';
import Layout2 from '../components/Layout/Layout2';
import ViewAllJobsDreamBig from '../components/GCCIndia/ViewAllJobsDreamBig';
import CardRow from '../components/GCCIndia/CardRow';



class GCCIndia extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <Heading />
            <Operations />
            
            <CardRow></CardRow>
            {/* <Analytics /> */}
            {/* <Tech /> */}
            {/* <Awards /> */}
            {/* <MoreAbout /> */}
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default GCCIndia;