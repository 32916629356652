import React from "react";

import Broaden_Horizon from '../../assets/images/programmes/Website_Icons_Broaden_Horizon.png';
import Learn_from_Experts from '../../assets/images/programmes/Website_Icons_Learn_from_Experts.png';
import Develop_Practical from '../../assets/images/programmes/Website_Icons_Develop_Practical_Skills.png';
import Network_Collaborate from '../../assets/images/programmes/Website_Icons_Network_Collaborate.png';


class MainContent extends React.Component {
    render() {
        return(
            <div>
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                {/* <p>Our LEAP programme turns talented graduates into future leaders of the business. The LEAP programme not only reveals the skills behind brewing and selling our beers but also examines how many areas of our business are vital to the success of our products. In 12 months, you can become a team leader or develop your technical skills to shape our organization’s future.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <p>It is a transformative learning experience tailored exclusively for AB InBev Israel to ensure our employees' continued growth and success. We've crafted a one-of-a-kind learning journey that delves deep into the intricacies of our organization's various functions.</p>
                                <p>The program is more than just a training initiative; it's a holistic journey that will immerse participants in the diverse functions that drive our company's success. From the pivotal areas of Procurement and Supply to the critical domains of Sustainability and Logistics, this program offers an exclusive opportunity to explore the inner workings of our organization.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <h3>What to Expect</h3>
                                <p>Participants will dive into a series of engaging and insightful sessions led by esteemed speakers from every corner of our organization. Guided by their expertise and real-world experiences, these sessions are carefully curated to provide our participants with an insider's view of the unique functions that set our company apart in the industry. Each session will give them valuable insights into the strategies, challenges, and innovations underpinning our success.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <h3>Learning from the Best</h3>
                                <p>Our industry-leading professionals from within the organization are at the heart of the program. Guided by their expertise and real-world experiences, participants will learn the theoretical aspects and the practical nuances that contribute to the smooth functioning of our organization. This approach ensures that they understand the concepts and acquire the skills needed to excel in their role.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="common-programme-content key_takeaways">
                    <div className="container">
                    <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <h3>Key Takeaways</h3>
                        <ul>
                                    <li>
                                    <img src={Broaden_Horizon}/>
                                        <b>Broaden Horizon</b> Gaining a comprehensive understanding of the multifaceted functions that drive our organization's growth.
                                    </li>
                                    <li>
                                    <img src={Learn_from_Experts}/>
                                        <b>Learn from Experts</b> Benefiting from the knowledge and experience of our industry veterans, who will share insights that can't be found in textbooks.
                                    </li>
                                    <li>
                                    <img src={Develop_Practical}/>
                                        <b>Develop Practical Skills</b> Acquire skills that are directly applicable to their role, enhancing their performance and contribution.
                                    </li>
                                    <li>
                                    <img src={Network_Collaborate}/>
                                        <b>Network & Collaborate</b>Connect with colleagues from various functions, fostering collaboration and cross-functional understanding.
                                    </li>
                                    
                                </ul>
                                </div>
                                </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainContent;