import React from 'react';
import Banner from '../components/BrewProgramme/Banner';
import MainContent from '../components/BrewProgramme/MainContent';
import Layout2 from '../components/Layout/Layout2';

class BrewProgramme extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <MainContent />
        </Layout2>
      </>
    );
  }
}

export default BrewProgramme;