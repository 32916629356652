import React from "react";
import Layout2 from "../components/Layout/Layout2";

function PrivacyPolicy() {
  return (
    <div>
      <Layout2>
        <section className="top_pera CookiePolicy">
          <div className="container">
            <div className="row">
             <div className="col-12">
                <h1>Cookies Policy</h1>
             <p>
                Cookies and beer – a match you wouldn't expect, right?
              </p>
              <p>
              We are using cookies on our website. With this document we want to explain what these are (yep, not the chocolate biscuits you had in mind), why we use them and your rights to control our use of them.
              </p>
              <p>This Cookie Policy should be read together with our Privacy Notice, which is also applicable if the use of certain cookies also involves the processing of ‘personal data’. You can consult our Privacy Notice for more information on how we process your personal data.</p>
             </div>
             <div className="col-12">
                <h2>Contents</h2>
                <ul className="QTag">
                    <li><a href="#WhoWeAre">What are cookies?</a></li>
                    <li><a href="#DoCollect">What categories of cookies may be used?</a></li>
                    <li><a href="#DrinkingAge">How long is cookie information stored?</a></li>
                    <li><a href="#SocialLinks">Is cookie information passed on to third parties ?</a></li>
                    <li><a href="#UsePersonalData"> How can I see which cookies were installed on my device and delete them?</a></li>
                    <li><a href="#SharePersonalData"> Changes to our Cookie Policy</a></li>
                </ul>
             </div>
             <div className="col-12 fullSection" id="WhoWeAre">
                <h2>What are cookies?</h2>
                <p>
                        Cookies are small text files placed in the browser of your computer or mobile device by a website’s server or application when you visit a website or use a (mobile) application. The cookie file contains a unique code with which your browser can be recognized during the visit to the online service or during consecutive, repeated visits. Cookies generally make the interaction between the visitor and the website or application easier and faster and help the visitor to navigate between the different parts of a website or application. Cookies cannot be used to run programs or deliver viruses to your device. You can learn more about cookies by visiting <a href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a>.
                    </p>

                    <p>
                        However, other tracking mechanisms may also fall under the definition of ‘cookies’, think about techniques such as the use of pixels or web beacons.
                    </p>

                    <p>
                        On this website, we currently only use strictly necessary cookies.
                    </p>
             </div>
             <div className="col-12 fullSection" id="DoCollect">
                <h2>What categories of cookies may be used?</h2>
                <p>
                    The strictly necessary cookies on this website are used for security reasons and will ensure our website to work properly. These cannot be rejected, and your consent will not be requested.
                </p>

                <p>
                    Where your consent is not requested, you can still set your browser to block or alert you about these cookies, but some parts of the site or service may then not work.
                </p>

                <p>
                    For a full list of individual cookies, please see below.
                </p>

                <p>
                    More information on the cookies placed via our recruitment platform’s website you can find here.
                </p>
                <h3>List of strictly necessary cookies</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Cookie</th>
                            <th>Domain</th>
                            <th>Description</th>
                            <th>Duration</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>incap_ses_*</td>
                            <td>abinbev-israel.com</td>
                            <td>We protect our websites against unauthorized modifications and attacks that make them inaccessible (Denial-of-service attacks and distributed-denial-of-service attacks/DDoS) by placing them behind a Web Application Firewall (WAF) from the company Imperva Incapsula. This cookie contains a unique identification for your session (visit) so that any request from your browser to our web servers to send a specific page (HTTP request) can be checked to see whether it relates to the same session.</td>
                            <td>Session</td>
                            <td>Necessary</td>
                        </tr>
                        <tr>
                            <td>visid_incap_*</td>
                            <td>abinbev-israel.com</td>
                            <td>Incapsula DDoS Protection and Web Application Firewall: the cookie on which sessions are related to a specific visitor (visitor representing a specific computer) in order to identify clients which have already visited Incapsula.</td>
                            <td>1 Year</td>
                            <td>Necessary</td>
                        </tr>
                        <tr>
                            <td>nlbi_*</td>
                            <td>abinbev-israel.com</td>
                            <td>Incapsula DDoS Protection and Web Application Firewall: Load balancing cookie. To ensure requests by a client are sent to the same origin server.</td>
                            <td>Session</td>
                            <td>Necessary</td>
                        </tr>
                    </tbody>
                </table>
             </div>
             <div className="col-12 fullSection" id="DrinkingAge">
                <h2>How long is cookie information stored?</h2>
                <p>
                Cookies can be stored on your computer or mobile device for different periods of time. Depending on the type of cookie, they (and the information they collect) are automatically deleted when you close your browser (these are the so-called “session cookies”), in other cases, these cookies remain stored for a longer period of time and can also be used during a subsequent visit to this website (these are the so-called “permanent cookies”), these will remain valid until its set expiration date, unless deleted by you before the expiration date
                </p>
                <p>Please consult the above overview for more information on the retention periods.</p>
             </div>
             <div className="col-12 fullSection" id="SocialLinks">
                <h2>Is cookie information passed on to third parties ?</h2>
                <p>A distinction can be made between two types of cookies:</p>
                <p>
                    <strong>First Party Cookies: </strong> 
                    Direct or first-party cookies are cookies that we manage ourselves and that are specific to the website visited or used.
                </p>

                <p>
                    <strong>Third-Party Cookies: </strong> 
                    Indirect cookies or third-party cookies are cookies that are managed and placed by a third party. These cookies therefore ensure that certain data is transmitted to third parties by your visit to our website. These may be, for example, cookies from social media or advertisers. It is possible that these third parties gain access to specific cookie information, e.g., in order to offer you personalized advertisements or to collect your feedback.
                </p>

                <p>
                    Currently, there are no cookies set by third parties on our website.
                </p>
             </div>
             <div className="col-12 fullSection" id="UsePersonalData">
                <h2>How can I see which cookies were installed on my device and delete them?</h2>
                <p>
                    If you want to know which cookies have been placed on your device or if you want to delete them, you can use the settings in your browser to do so.
                    The links below will explain how to do this.
                </p>

                <p>
                    Strictly necessary cookies cannot be modified because they are necessary to provide our online services. However, you can still set your browser to block or alert you about these cookies. Do note that some parts of the site or service may then not work.
                </p>

                <p><strong>Browser Controls:</strong></p>

                   <p>You can set and change your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our websites, though your access to some functionalities and areas of our website may be restricted. Please click below for detailed information on how to disable and delete cookies in some commonly used browsers:</p>
                        <ul>
                            <li>Microsoft Internet Explorer</li>
                            <li>Google Chrome</li>
                            <li>Apple Safari</li>
                            <li>Mozilla Firefox</li>
                </ul> 
             </div>
             <div className="col-12 fullSection" id="SharePersonalData">
                <h2>Changes to our Cookie Policy</h2>
                <p>
                We may update this document from time to time to reflect changes to cookies and similar technologies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
                </p>
                <p>Cheers!</p>
                <p>Version 1: Last updated 16/01/2024</p>
             </div>
            </div>
          </div>
        </section>
      </Layout2>
    </div>
  );
}

export default PrivacyPolicy;
