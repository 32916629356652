import React from "react";
class Content extends React.Component {
    render() {
        return(
            <div class="our-brands-content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12">
                            <p>We are the proud makers of more than 500 iconic brands, from the time-honored classics enjoyed globally to new local favorites. </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Content;