import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import budweiser_card from '../../assets/images/home/Budweiser_Card.png';
// import budweiser_magnum from '../../assets/images/home/budwsier_manum.png';
import corona_card from '../../assets/images/home/Corona_Card.png';
import fosters from '../../assets/images/home/fosters_hme.png';
import heywards from '../../assets/images/home/haywards.png';
import hoegaarden from '../../assets/images/home/Hoegaarden_home.png';
import knockout from '../../assets/images/home/kockout.png';
// import becks from '../../assets/images/home/becks.png';
import usa from '../../assets/images/home/USA_Flag.svg';
import mexico from '../../assets/images/home/mexico_Flag.svg';
import belgium from '../../assets/images/home/belgium_Flag.svg';
import india from '../../assets/images/home/india_flag.png';


import budwsier_manum from '../../assets/images/our_brands/budwsier_manum.png';
import becks from '../../assets/images/our_brands/becks.png';
import haywards from '../../assets/images/our_brands/haywards.png';
import kockout from '../../assets/images/our_brands/kockout.png';
import corona from '../../assets/images/our_brands/corona.png';
import Goose from '../../assets/images/our_brands/Goose-IPA-1.png';
import Stella from '../../assets/images/our_brands/Stella.png';
import Leffe from '../../assets/images/our_brands/Leffe.png';
import HOEg from '../../assets/images/our_brands/HOEg.png';
import Lowenbrau from '../../assets/images/our_brands/Lowenbrau.png';
import Negra from '../../assets/images/our_brands/Negra-Modelo-1.png';
import Spanten from '../../assets/images/our_brands/Spanten.png';
import Becksnon from '../../assets/images/our_brands/Becks-non-A.png';
import { Link } from "react-router-dom";
class OurBrands extends React.Component {
    render() {
        return(
            <div className="our-brands-home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="what-we-do-heading">
                                <h3 className="common-heading">OUR <span>BRANDS</span></h3>
                            </div>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12 carousel-brands ">
                            <Carousel fade>
                                
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={budwsier_manum}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                {/* <img src={budwsier_manum} alt={usa} />  */}
                                                <span>Budweiser</span>
                                            </h3>
                                            
                                                <div>
                                                <Link to="https://www.budweiser.com" target="blank" tabindex="0">www.budweiser.com</Link>
                                                </div>
                                                <p>The American-style lager was introduced in 1876 when company founder Adolphus Busch set out to create the United States' first truly national beer brand. Each batch of Budweiser stays true to the same family recipe used by five generations of Busch family brewmasters.</p>
                                                <p>Budweiser is a medium-bodied, flavorful, crisp, and pure beer with blended layers of premium American and European hop aromas, brewed for the perfect balance of flavor and refreshment.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={corona}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                            <h3>
                                                {/* <img src={mexico} alt={mexico} />  */}
                                                <span>Corona</span>
                                            </h3>
                                            <div>
                                                                <Link to="https://www.corona.com" target="blank" tabindex="0">www.corona.com</Link>
                                                                </div>
                                                                <p>Corona, a Mexican lager, is accentuated by slightly malty notes and fruity aromas from the fermentation process. It is balanced by a noticeable body that is light enough to quench thirst and bitter enough to deliver a desirable, crisp finish.</p>
                                                                <p>It is the most well-known and popular Mexican beer around the globe. Produced entirely in Mexico, Corona is exported to 180 countries worldwide.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={Goose}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Goose Island</h3>
                                                                <div>
                                                                <Link to="www.gooseisland.com" target="blank" tabindex="0">www.gooseisland.com</Link>
                                                                </div>
                                                                <p>Founded in 1988, Goose Island is a leading craft brewery in the Midwest. Inspired by European beer styles, founder John Hall aimed to bring exceptional brews to America. Settling in Chicago, he embraced the city's evolving tastes and ample fresh water. In 1992, Goose Island established a renowned barrel program. Today, their state-of-the-art facility produces world-class barrel-aged beers.</p>
                                                                <p>Goose Island’s finest creations, Matilda and Sofie, are now available in Israel.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={Stella}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Stella Artois</h3>
                                                                <div>
                                                                <Link to="www.stellaartois.com" target="blank" tabindex="0">www.stellaartois.com</Link>
                                                                </div>
                                                                <p>Stella Artois was initially crafted as a Christmas present for the residents of Leuven, Belgium. The beer's name holds significance, with "Stella" meaning "Star" in Latin and "Artois" the surname of its talented brewmaster, Sebastian Artois. Known for its pleasantly bitter and refreshing taste, Stella Artois boasts a crisp finish that leaves a lasting impression.</p>
                                                                <p>Stella Artois' crisp, refreshing flavor pairs well with intensely flavored Thai, Asian Fusion, and Indian dishes.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={Leffe}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Leffe</h3>
                                                                <div>
                                                                <Link to="www.leffe.com" target="blank" tabindex="0">www.leffe.com</Link>
                                                                </div>
                                                                <p>Leffe, an ale brewed 800 years ago by monks in Leffe Abbey, Dinant, Belgium, provided a safer alternative to water during a time of plague.</p>
                                                                <p>Today, Leffe is brewed in Leuven, and all Leffe variants are fermented with the same unique yeast, offering harmony with notes of vanilla, spicy clove/smoke, banana, and ripe fruits. Distinct ingredients like biscuit malts, roasted malts, and red fruits enhance the signature character of each variant.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={HOEg}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Hoegaarden</h3>
                                                                <div>
                                                                <Link to="www.hoegaarden.com" target="blank" tabindex="0">www.hoegaarden.com</Link>
                                                                </div>
                                                                <p>Hoegaarden pronounced who-gar-den, traces its origins back to 1445 to a small Belgian village where dedicated monks crafted a unique wheat beer. Through meticulous experimentation, the monks discovered the perfect blend of orange peel and coriander.</p>
                                                                <p>Hoegaarden has gained popularity and garnered numerous prestigious awards for its exceptional quality and taste. This naturally unfiltered beer has refreshing fruity, floral, and citrus flavors. It boasts a bright and easy-to-drink profile.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={Lowenbrau}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Löwenbräu</h3>
                                                                <div>
                                                                    <Link to="www.loewenbraeu.de" target="blank" tabindex="0">www.loewenbraeu.de</Link>
                                                                </div>
                                                                <p>Löwenbräu, a renowned German brewery, has its origins dating back to 1383 in Munich. Today, it stands as a prominent symbol of Bavarian beer culture, adhering to the strict standards of the German Purity Law.</p>
                                                                <p>Löwenbräu's flagship lager boasts a clean and crisp taste, showcasing the brewery's commitment to quality. Its rich golden color and balanced maltiness pair excellently with traditional German cuisines like bratwurst, sauerkraut, and pretzels.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={Negra}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Modelo Negra</h3>
                                                                <div>
                                                                    <Link to="www.modelousa.com" target="blank" tabindex="0">www.modelousa.com</Link>
                                                                </div>
                                                                <p>Modelo Negra is a Dunkel-style lager introduced in the 1920s by Austrian immigrants in Mexico.It has a bright, rich, amber hue and is sold in a uniquely shaped bottle.</p>
                                                                <p>This medium-bodied lager features slow-roasted caramel malts that deliver a rich flavor and remarkably smooth taste. Brewed longer to enhance flavors, Modelo Negra pairs well with traditional Mexican dishes, making it a delightful choice to complement any recipe.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={Spanten}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Spaten</h3>
                                                                <div>
                                                                    <Link to="www.spatenbraeu.de" target="blank" tabindex="0">www.spatenbraeu.de</Link>
                                                                </div>
                                                                <p>Spaten, a renowned German brewery with a rich history dating back to 1397, continues to produce exceptional beers today. Known for its commitment to traditional brewing methods, Spaten offers a range of high-quality brews.</p>
                                                                <p>Their flagship beer, Spaten Lager, is a classic German lager with a balanced and crisp taste. It pairs well with hearty Bavarian dishes such as sausages, pretzels, and roasted meats, complementing their flavors with its refreshing character.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div className="images">
                                        <img
                                            className="d-block w-100"
                                            src={Becksnon}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="details">
                                        <Carousel.Caption>
                                        <h3>Beck’s</h3>
                                                                <div>
                                                                    <Link to="www.becks.de" target="blank" tabindex="0">www.becks.de</Link>
                                                                </div>
                                                                <p>Beck's, a renowned German brand, is the top-selling German beer globally, loved in 80+ countries. Known for its innovation, Beck's introduced green bottles when brown glass was scarce, pioneering this packaging trend.</p>
                                                                <p>Brewed as an authentic German pilsner, Beck's adheres to the German Beer Purity Law, using four natural ingredients—Barley, Hops, Water, and Yeast—for its unique aroma, bitterness, and hoppy aftertaste.</p>
                                                                <p>Beck's 0.0 is also available in Israel.</p>
                                        </Carousel.Caption>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default OurBrands;