import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner sales-banner">
                <div className="common-banner-inner">
                    <h1>SALES <span className="red-text">VISIONARIES<br/>PROGRAMME</span> </h1>
                </div>
            </section>
        );
    }
}
export default Banner;