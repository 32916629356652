import React from "react";
import marketing from '../../assets/images/BU_India/marketing.jpeg';
class Marketing extends React.Component {
    render() {
        return(
            <div class="bu-common-bu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div class="bu-common-main">
                                <div class="bu-left">
                                    <h4 class="d-block d-sm-none">Marketing</h4>
                                    <div class="image">
                                        <img alt="Marketing" src={marketing} width="100%" />
                                    </div>
                                </div>
                                <div class="bu-right">
                                    <h4 class="d-none d-sm-block">Marketing</h4>
                                    <p>Our Marketing team utilizes their creativity and capability to bring an intense focus on consumer experience and give our brands life. This team uses data and insights to identify global trends.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Marketing;