import React from "react";
import Brewdat from '../../assets/images/home/Brewdat.png';
import Cybersecurity from '../../assets/images/home/Cybersecurity.png';
import Innovation from '../../assets/images/home/Innovation.png';
import ghq_india from '../../assets/images/home/ghq_india.png';
import { Link } from "react-router-dom";
class WhatWeDo extends React.Component {
    render() {
        return(
            <div className="what-we-do home_who">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="what-we-do-heading">
                                <h3 className="common-heading">WHAT <span>WE DO</span></h3>
                                <p>Founded in 2019, the Israel Tech Hub initially prioritized the analysis of cyber threats and potential attacks, a vital aspect in today's increasingly digital world for strong defense preparedness. Over time, we have broadened our horizons to include two additional teams: BrewDat and Innovation.</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <ul>
                                <li>
                                    <div className="port">
                                        <div className="image">
                                            <img src={Brewdat} />
                                        </div>
                                        <div className="portfolioDisc">
                                            <div className="details">
                                                <div className="details-main">
                                                    <h2>Cybersecurity</h2>
                                                    <div className="describe_cls">
                                                        <p>We secure global cloud-native applications, customer data, and supply chain operations through a network of security centers worldwide, fortifying e-commerce and logistics against cyber threats, and fostering trust in digital interactions.</p>
                                                    </div>
                                                    <Link to="/cybersecurity">LEARN MORE</Link>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div className="port">
                                        <div className="image">
                                            <img src={Cybersecurity} />
                                        </div>
                                        <div className="portfolioDisc">
                                            <div className="details">
                                                <div className="details-main">
                                                    <h2>BrewDat</h2>
                                                    <div className="describe_cls">
                                                        <p>BrewDat, AB InBev’s official data platform, revolutionizes data-driven decision-making. It empowers teams by streamlining data collection, processing, and management. Ready-to-use datasets simplify analytics, enabling data scientists to experiment and visualize results securely.</p>
                                                    </div>
                                                    <Link to="/brewDat">LEARN MORE</Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="port">
                                        <div className="image">
                                            <img src={Innovation} />
                                        </div>
                                        <div className="portfolioDisc">
                                            <div className="details">
                                                <div className="details-main">
                                                    <h2>Innovation</h2>
                                                    <div className="describe_cls">
                                                        <p>Beer Garage or the Innovation team, is the global tech innovation division of AB InBev. Operating in thriving startup hubs worldwide, our goal is to empower our network to take the leap by bringing the future one step closer.</p>
                                                    </div>
                                                    <Link to="/innovation">LEARN MORE</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default WhatWeDo;