import React from "react";
import { Link } from "react-router-dom";
var ReactRotatingText = require('react-rotating-text');
class ViewAllJobsDreamBig extends React.Component {
    render() {
        return(
            <div className="common-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3>DREAM BIG WITH AB INBEV</h3>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="animate-scroll-new">WE ARE &nbsp;
                                <ReactRotatingText items={['BOLD', 'RESILIENT', 'AMBITIOUS', 'COLLABORATIVE']} />
                            </div>
                            <div className="animate-scroll-new">WE ARE BASED IN TEL AVIV</div>
                            <div className="animate-scroll-new">WORKING IN &nbsp;
                                <ReactRotatingText items={['Cybersecurity', 'Innovation', 'TECH']} />
                            </div>
                            <Link to="https://www.comeet.com/jobs/ABInBev/54.004" target="_blank">VIEW ALL JOBS</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ViewAllJobsDreamBig;