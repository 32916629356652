import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Liat from '../../../assets/images/GCC_Leaders/Liat.png';
class MarceloFonseca extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Marcelo Fonseca" src={Liat} />
                    </div>
                    <div class="details">
                        <h4>LIAT VAKNIN</h4>
                        <p>Global Director, Offensive Services</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={Liat} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>LIAT VAKNIN</h4>
                            <p class="sub-head">Global Director, Offensive Services</p>
                            <p class="content">Liat is a dedicated and results-driven Cybersecurity professional with a demonstrated track record of building, leading and guiding high-impact cyber security teams, mainly focused in Cloud Threat landscape, Adverserial Red Team, and Security Research across various domains. Proficient in developing and executing innovative research strategies and identifying emerging threats.</p>
                            <p class="content">In her role as the Director of Offensive Services at ABInBev, Liat provides out of the box and critical thinking - aiming to stay ahead of emerging threats. In addition, she sets strategic planning and priorities, fosters cross-functional collaboration, and ensures alignment with organizational security objectives. Liat advocates for a proactive offensive approach as hacking involves a different way of looking at problems that no one's thought of.</p>
                            <p class="content">Liat holds a bachelor's degree in Business & Information Systems and multiple professional certifications such as OSCP, OSCE, GXPN, Windows Internals and many more to come as to her life is an endless educational journey.</p>
                            
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default MarceloFonseca;