import React from 'react';

class HistoryDetails extends React.Component {
  render() {
    return (
        <h2>sdsadasd</h2>
    );
  }
}

export default HistoryDetails;