import React from 'react';
import ViewAllJobsDreamBig from '../components/GCCIndia/ViewAllJobsDreamBig';
import Banner from '../components/GCCLocations/Banner';
import LocationsList from '../components/GCCLocations/LocationsList';
import ViewMore from '../components/GCCLocations/ViewMore';
import Layout2 from '../components/Layout/Layout2';

class GCCLocations extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <LocationsList />
            <ViewMore />
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default GCCLocations;