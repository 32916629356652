import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import sumanaGM from '../../../assets/images/GCC_Leaders/sumanaGM.jpeg';
class SumanaGM extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Sumana G M" src={sumanaGM} />
                    </div>
                    <div class="details">
                        <h4>Sumana G M</h4>
                        <p>Global Director, Data Engineering</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={sumanaGM} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Sumana G M</h4>
                            <p class="sub-head">Global Director, Data Engineering</p>
                            <p class="content">Sumana was born in Shimoga and brought up in Bangalore. She has a bachelor's in Computer Science Engineering. Sumana has 12+ years of Industry expertise in Technologies like ETL, Cloud and Data platforms, along with skilled Management expertise. Her role entails creating a vision and strategy for Data and Quality Assurance capabilities and Test Automations.<br/><br/>Sumana topped 'Catalyst', a leadership development program customised by Oscar Murphy Life Strategists for AB InBev and is recognised as the Top 250 Managers in India 2021 by the Great Manager Awards organised by People Business Consulting.<br/><br/>Beyond work, Sumana is also passionate about below hobbies:<br/>Nature photography, bird watching, music, dancing, mentoring and coaching, CSR Pot painting, DIY bottles, swimming, yoga, Pranayama, early morning long walks, exploring new places, and new learning activities.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default SumanaGM;