import React, {useState} from "react";
import joanna from '../../../assets/images/GCC_Leaders/joanna.png';
import Modal from 'react-bootstrap/Modal';
class JoannaMoraes extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div>
                {/* <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="profile-details">
                        <div class="image">
                            <img alt="Joanna Moraes" src={joanna} />
                        </div>
                        <div class="details">
                            <h4>Uri Shaham</h4>
                            <p>Senior Global Director, Data & Architecture</p>
                        </div>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={joanna} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Uri Shaham</h4>
                            <p class="sub-head">Senior Global Director, Data & Architecture</p>
                            <p class="content">In March 2020, Uri joined AB InBev as a Global Director for Data & Architecture, bringing his extensive experience in the tech industry based in Israel. He played a vital role in designing and delivering BrewDat, ABI's global data platform.</p>
                            <p>His journey began in 1998 when he started working as a C++ developer. Over the course of his 20+ year career, Uri has held senior positions in several industry-leading companies. He has contributed to the development of global products that have disrupted the retail and telecommunications industries.</p>
                            <p>Outside of work, Uri is happily married and takes pride in being a father to three daughters.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div> */}
            </div>
        );
    }
}
export default JoannaMoraes;