import React from 'react';
import Banner from '../components/GCCBenefits/Banner';
import Rewarding from '../components/GCCBenefits/Rewarding';
import TabsBenefits from '../components/GCCBenefits/TabsBenefits';
import ViewMore from '../components/GCCBenefits/ViewMore';
import ViewAllJobsDreamBig from '../components/GCCIndia/ViewAllJobsDreamBig';
import Layout2 from '../components/Layout/Layout2';

class GCCBenefits extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <Rewarding />
            <TabsBenefits />
            <ViewMore />
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default GCCBenefits;