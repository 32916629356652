import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import sandeepBalan from '../../../assets/images/GCC_Leaders/sandeepBalan.jpeg';
class SandeepBalan extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Sandeep Balan" src={sandeepBalan} />
                    </div>
                    <div class="details">
                        <h4>Sandeep Balan</h4>
                        <p>Global Director, Data Engineering</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={sandeepBalan} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Sandeep Balan</h4>
                            <p class="sub-head">Global Director, Data Engineering</p>
                            <p class="content">Sandeep Balan is the Global Director for Data Engineering at AB InBev, GCC India. During his career spanning over 17 years, he has played various Tech roles as an Entrepreneur and Mentor. He is passionate about solving business problems through data products. An alumnus of ISB Hyderabad and GEC Trichur, Sandeep also has a PG in Big Data and Machine Learning from Illinois Tech.<br/><br/>Sandeep started his career with Infosys as a Data & Analytics Consultant working for clients across North America, the UK, and the Middle East. Post that, he joined a start-up to set up the Data and Analytics team. Before joining AB InBev, Sandeep led the Data and Analytics team for Investment Banking Division for Goldman Sachs from Bangalore.<br/><br/>In his current role in AB InBev, based in Bangalore, he is leading a team of Data Engineering Practitioners to build the NexGen data platform for the firm. He loves travelling and reading and is currently dabbling his hands-on exploring alternate careers around Photography and Acoustic Guitar</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default SandeepBalan;