import React from "react";
class ViewAllJobs extends React.Component {
    render() {
        return(
            <div>
                {/* <div class="see-all-brands">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12">
                            <div class="details">
                                <h2>
                                    <span>YOU LOVE OUR BRANDS.</span>
                                    <br /> START LOVING YOUR CAREER.
                                </h2>
                                <p>
                                    <b>We’re seeking passionate people</b> who’ll be inspired by brewing the world’s <br /> most loved beers, building iconic brands, and creating meaningful experiences.
                                </p>
                                <a href="https://ab-inbev.skillate.com/" target="_blank">VIEW ALL JOBS</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            </div>
        );
    }
}
export default ViewAllJobs;