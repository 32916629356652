import React from "react";
import location from '../../assets/images/location_new_mobile.png';
class Location extends React.Component {
    render() {
        return(
            <div class="col-md-4 col-xs-12 col-sm-4">
                <div class="benefits-list">
                    <div class="image">
                        <img src={location} width="100%" alt="OUR LOCATIONS" />
                    </div>
                    <div class="details">
                        <h2>OUR LOCATIONS</h2>
                        <p>View our Bangalore offices.</p>
                        <a href="/bu-our-locations">
                            <span>EXPLORE OUR LOCATIONS</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Location;