import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import personal_insurance from '../../assets/images/GCCBenefits/personal_insurance.png';
import term_insurance from '../../assets/images/GCCBenefits/term_insurance.png';
import medical_insurance from '../../assets/images/GCCBenefits/medical_insurance.png';
import enhanced_maturity_benefits from '../../assets/images/GCCBenefits/enhanced_maturity_benefits.png';
import healthy_mind from '../../assets/images/GCCBenefits/healthy_mind.png';
import critical_illness from '../../assets/images/GCCBenefits/critical_illness.png';
import diversity from '../../assets/images/GCCBenefits/diversity.png';
import child_care from '../../assets/images/GCCBenefits/child_care.png';
import elder_care from '../../assets/images/GCCBenefits/elder_care.png';
import creche from '../../assets/images/GCCBenefits/creche.png';
import benefithub from '../../assets/images/GCCBenefits/benefithub.png';
import wellness_benefit from '../../assets/images/GCCBenefits/wellness_benefit.png';
import klib from '../../assets/images/GCCBenefits/klib.png';
import consellor from '../../assets/images/GCCBenefits/consellor.png';
import company_transport from '../../assets/images/GCCBenefits/company_transport.png';
import carenine_program from '../../assets/images/GCCBenefits/carenine_program.png';
import beer_vouchers from '../../assets/images/GCCBenefits/beer_vouchers.png';
class TabsBenefits extends React.Component {
    render() {
        return(
            <div className="tabs-benefits">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" justify>
                                <Tab eventKey="home" title="Employee Insurance">
                                    <ul class="bu-bhq-list-icon">
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Personal accident insurance - 3 times annual CTC" src={personal_insurance} />
                                                </div>
                                                <p>Personal accident insurance - 3 times annual CTC</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Term Life Insurance – 3 Times annual CTC" src={term_insurance} />
                                                </div>
                                                <p>Term Life Insurance – 3 Times annual CTC</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab eventKey="profile" title="My Health & Wellness">
                                    <ul class="bu-bhq-list-icon">
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Medical Insurance, Annual Health Check Up, Virtual Care Room, OPD Cover, Dental Care, Vision Care" src={medical_insurance} />
                                                </div>
                                                <p>Medical Insurance, Annual Health Check Up, Virtual Care Room, OPD Cover, Dental Care, Vision Care</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Enhanced Maternity Benefit" src={enhanced_maturity_benefits} />
                                                </div>
                                                <p>Enhanced Maternity Benefit</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Healthy Mind - Mental health related expenses" src={healthy_mind} />
                                                </div>
                                                <p>Healthy Mind - Mental health related expenses</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Critical Illness Top Up Cover" src={critical_illness} />
                                                </div>
                                                <p>Critical Illness Top Up Cover</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Diversity &amp; Inclusion Cover" src={diversity} />
                                                </div>
                                                <p>Diversity &amp; Inclusion Cover [Surrogacy, Physical Disability, Gender Reassignment Surgery]</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab eventKey="contact" title="Family Health & Wellness">
                                    <ul class="bu-bhq-list-icon">
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Child Care" src={child_care} />
                                                </div>
                                                <p>Child Care</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Elder Care" src={elder_care} />
                                                </div>
                                                <p>Elder Care</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Creche program" src={creche} />
                                                </div>
                                                <p>Creche program</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab>
                                <Tab eventKey="leisure" title="My Leisure & Lifestyle">
                                    <ul class="bu-bhq-list-icon">
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="BenefitHub – Discounts &amp; Deals on different brands" src={benefithub} />
                                                </div>
                                                <p>BenefitHub – Discounts &amp; Deals on different brands</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Wellness Benefi" src={wellness_benefit} />
                                                </div>
                                                <p>Wellness Benefit</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="klib Corporate Library" src={klib} />
                                                </div>
                                                <p>klib Corporate Library</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="to1help Professional Counsellors" src={consellor} />
                                                </div>
                                                <p>1to1help Professional Counsellors</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Company Transport" src={company_transport} />
                                                </div>
                                                <p>Company Transport</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="CareNine Program Educating parents to be" src={carenine_program} />
                                                </div>
                                                <p>CareNine Program Educating parents to be</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="details">
                                                <div class="image">
                                                    <img alt="Beer Vouchers - Cheers! Treat yourself to our most loved beers" src={beer_vouchers} />
                                                </div>
                                                <p>Beer Vouchers - Cheers! Treat yourself to our most loved beers</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default TabsBenefits;