import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import gptw from '../../assets/images/GCC_India/gptw.png';
import best_place_women from '../../assets/images/GCC_India/best_place_women.png';
import nasscom_logo from '../../assets/images/GCC_India/nasscom-logo.png';
import great_manager from '../../assets/images/GCC_India/great-manager-logo.png';
import zinnov_awards_logo from '../../assets/images/GCC_India/zinnov_awards_logo.png';
import D2C from '../../assets/images/GCC_India/D2C.png';
import analytics_india_magzine from '../../assets/images/GCC_India/analytics-india-magzine.png';
import Future_Of_Work_Summit from '../../assets/images/GCC_India/Future-Of-Work-Summit.png';
import { Link } from "react-router-dom";
class Awards extends React.Component {
    render() {
        return(
            <div className="awards-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3 className="heading">Awards</h3>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="carousel-banner">
                                <Carousel fade>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={gptw} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>Great Place To Work®️ Certification</h4>
                                                <p>The Great Place To Work® certification is used globally to understand employee perceptions about their workplace. AB InBev GCC scored 87 on the Trust Index© Employee Survey, including high scores across all 5 dimensions of credibility, respect, fairness, pride, and camaraderie between people.This certification is the gold standard in recognizing great workplaces with high-trust and high-performance culture.</p>
                                                <Link class="view-award" to="https://www.greatplacetowork.in/great/company/gcc-services-india-private-limited-ab-inbev" target="_blank">View this award</Link>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={best_place_women} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>Best Workplaces™ for Women 2021 Certification</h4>
                                                <p>AB InBev GCC India is now recognized one of India’s Top 10 Best Workplaces™ for Women 2021 by Great Place To Work®️. Over 700 organizations were assessed on a variety of parameters like the overall Trust Index© feedback of women employees, parity in the experience of women employees, and representation of women across all levels in the organization.</p>
                                                <Link class="view-award" to="https://www.greatplacetowork.in/great/company/gcc-services-india-private-limited-ab-inbev" target="_blank">View this award</Link>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={nasscom_logo} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>NASSCOM GCC Awards</h4>
                                                <p>We won in 2 categories at the NASSCOM GCC Awards 2021. Excelling During the Crisis Award – for embracing change and demonstrating the ability to adapt and rapidly respond to disruptions and driving enhanced support to global business. Excellence in Building Talent of The Future Award – for effective talent management practices that enable us to create a workforce that is future ready.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={great_manager} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>The Great Manager Awards 2021</h4>
                                                <p>We won awards in all three categories at The Great Manager Awards 2021 organized by People Business Consulting, media partner The Economic Times.<br/><br/>These wins reaffirm our belief in the resilience and caliber of teams across AB InBev India who have always focused on innovation and impact-driven ventures to build a pioneering future.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={zinnov_awards_logo} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>Zinnov Awards</h4>
                                                <p>AB InBev won in 2 organization-level categories at the Zinnov Awards 2021. The Great Place to Innovate Award – for enabling an innovative mindset and culture within the organization.<br/><br/>The Excellence in Remote Talent Engagement Award – for successfully keeping our remote workforce engaged, productive, and connected during the pandemic.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={D2C} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>Dare2Compete Awards</h4>
                                                <p>We featured amongst the top 10 desired employers in the FMCG sector by India's top B-School students in Dare2Compete's Campus Employer Branding Study 2021. This first of its kind, exhaustive and authentic survey attracted over 7,000 B-School and E-School students.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={analytics_india_magzine} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>Analytics India Magazine Award</h4>
                                                <p>We ranked 4th in Analytics India Magazine's Top 50 Firms in India for Data Scientists to Work For. Organizations were ranked based on how well suited a company’s policies are for their employees and Ab InBev scored best in Benefits & Well-being category.<br/><br/>Our people are our greatest asset, and this recognition is a mark of our perseverance towards excellence.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="common-car-details">
                                            <div class="common-car-left">
                                                <img class="place-women" src={Future_Of_Work_Summit} />
                                            </div>
                                            <div class="common-car-right">
                                                <h4>Future of Work Summit 2021 Award</h4>
                                                <p>Human–machine partnerships are the next wave in the future of work, and at AB InBev, we are proud to be recognized as a winner at the Future of Work Summit 2021 for our commitment to building the workplace of the future!<br/><br/>As we adopt new ways of work, our technology and business functions are converging with robust data & analytics enabling us to foster bias-free People processes.</p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Awards;