import React from "react";
import  Strategic_Leadership from '../../assets/images/programmes/Website_Icons_Strategic_Leadership.png';
import Team_Empowerment from '../../assets/images/programmes/Website_Icons_Team_Empowerment.png';
import Inclusive_Culture from '../../assets/images/programmes/Website_Icons_Inclusive_Culture.png';
import Adaptive_Leadership from '../../assets/images/programmes/WebsiteIcons_Adaptive_Leadership.png';
class MainContent extends React.Component {
    render() {
        return(
            <div>
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                <p>The Managing@ABI program is a 3-day experiential leadership program that is designed to help our employees build their skills as new managers and develop their understanding of the company’s Leadership Capabilities and People Cycle. It is for entry-level managers who are managing teams, projects, or initiatives that require motivating or coordinating efforts of multiple people.</p>
                                <p>The program includes experiential projects, participant-led discussions, coaching, feedback, and skill sessions focused on leading with purpose.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common-programme-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                                <h3>What to Expect</h3>
                                <p>
                                Over 3 days, participants will master key leadership capabilities, from translating strategy into execution to fostering diverse teams and building unwavering trust. They will learn to embrace a culture of growth, learn from mistakes, and champion innovation. The program will also aid participants in navigating change, communicating effectively, and developing resilience.
                                </p>
                                <p>
                                Participants can expect a continuous learning experience that goes beyond the 3 days of training. Before, during, and after the program, participants will practice, discuss, collaborate, and apply concepts they learned.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="common-programme-content key_takeaways">
                    <div className="container">
                    <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <h3>Key Takeaways</h3>
                        <ul>
                                    <li>
                                    <img src={Strategic_Leadership}/>
                                        <b>Strategic Leadership </b> Translate strategic visions into actionable steps, ensuring the realization of business goals.
                                    </li>
                                    <li>
                                    <img src={Team_Empowerment}/>
                                        <b>Team Empowerment </b> Forge highly effective, diverse teams that are not just functional but also cohesive and motivated.
                                    </li>
                                    <li>
                                    <img src={Inclusive_Culture}/>
                                        <b>Inclusive Culture </b> Cultivate an inclusive environment that values diverse perspectives and fuels innovation.
                                    </li>
                                    <li>
                                    <img src={Adaptive_Leadership}/>
                                        <b>Adaptive Leadership </b> Navigate change and uncertainty with grace, guiding the team through uncharted waters.
                                    </li>
                                    
                                </ul>
                                </div>
                                </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainContent;