import React from 'react';
import Layout2 from '../components/Layout/Layout2';
import Banner from '../components/SalesVisinoriesProgramme/Banner';
import MainContent from '../components/SalesVisinoriesProgramme/MainContent';

class SalesVisinoriesProgramme extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <MainContent />
        </Layout2>
      </>
    );
  }
}

export default SalesVisinoriesProgramme;