import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner brew-banner">
                <div className="common-banner-inner">
                    <h1>Re<span className="red-text">Combinate </span> </h1>
                    <p>by TAU Ventures & AB InBev </p>
                </div>
            </section>
        );
    }
}
export default Banner;