import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import App from './App';
import OurHistory from './pages/OurHistory';
import About from './pages/About';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Sustainability from './pages/Sustainability';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import OurValues from './pages/OurValues';
import DiversityInclusion from './pages/DiversityInclusion';
import GCCIndia from './pages/GCCIndia';
import BUIndia from './pages/BUIndia';
import GCCLeaders from './pages/GCCLeaders';
import BULeaders from './pages/BULeaders';
import LeapProgramme from './pages/LeapProgramme';
import BrewProgramme from './pages/BrewProgramme';
import GlobalManagementTraineeProgramme from './pages/GlobalManagementTraineeProgramme';
import SalesVisinoriesProgramme from './pages/SalesVisinoriesProgramme';
import SupplyManagementTraineeship from './pages/SupplyManagementTraineeship';
import OurBrands from './pages/OurBrands';
import FourNotFour from './pages/404';
import GCCBenefits from './pages/GCCBenefits';
import GCCBetterWorldInitiatives from './pages/GCCBetterWorldInitiatives';
import GCCLocations from './pages/GCCLocations';
import BUBenefits from './pages/BUBenefits';
import BUBetterWorldInitiatives from './pages/BUBetterWorldInitiatives';
import BULocations from './pages/BULocations';
import Final from './pages/Final';
import ReCombinate from './pages/ReCombinate';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import withAgeVerification from './components/withAgeVerification';


const AppWithAgeVerification = withAgeVerification( App);
const HomeWithAgeVerification = withAgeVerification(Home);
const OurHistoryWithAgeVerification = withAgeVerification(OurHistory);
const AboutWithAgeVerification = withAgeVerification(About);
const ContactWithAgeVerification = withAgeVerification(Contact);
const SustainabilityWithAgeVerification = withAgeVerification(Sustainability);
const OurValuesWithAgeVerification = withAgeVerification(OurValues);
const DiversityInclusionWithAgeVerification = withAgeVerification(DiversityInclusion);
const GCCIndiaWithAgeVerification = withAgeVerification(GCCIndia);
const BUIndiaWithAgeVerification = withAgeVerification(BUIndia);
const ReCombinateWithAgeVerification = withAgeVerification(ReCombinate);
const GCCLeadersWithAgeVerification = withAgeVerification(GCCLeaders);
const BULeadersWithAgeVerification = withAgeVerification(BULeaders);
const LeapProgrammeWithAgeVerification = withAgeVerification(LeapProgramme);
const BrewProgrammeWithAgeVerification = withAgeVerification(BrewProgramme);
const GlobalManagementTraineeProgrammeWithAgeVerification = withAgeVerification(
  GlobalManagementTraineeProgramme
);
const SalesVisinoriesProgrammeWithAgeVerification = withAgeVerification(SalesVisinoriesProgramme);
const SupplyManagementTraineeshipWithAgeVerification = withAgeVerification(
  SupplyManagementTraineeship
);
const OurBrandsWithAgeVerification = withAgeVerification(OurBrands);
const FourNotFourWithAgeVerification = withAgeVerification(FourNotFour);
const GCCBenefitsWithAgeVerification = withAgeVerification(GCCBenefits);
const GCCBetterWorldInitiativesWithAgeVerification = withAgeVerification(
  GCCBetterWorldInitiatives
);
const GCCLocationsWithAgeVerification = withAgeVerification(GCCLocations);
const BUBenefitsWithAgeVerification = withAgeVerification(BUBenefits);
const BUBetterWorldInitiativesWithAgeVerification = withAgeVerification(
  BUBetterWorldInitiatives
);
const BULocationsWithAgeVerification = withAgeVerification(BULocations);
const FinalWithAgeVerification = withAgeVerification(Final);
const PrivacyPolicyWithAgeVerification = withAgeVerification(PrivacyPolicy);
const CookiePolicyWithAgeVerification = withAgeVerification(CookiePolicy);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} exact />
      <Route path="/home" element={<HomeWithAgeVerification />} />
      <Route path="/our-history" element={<OurHistoryWithAgeVerification/>} />
      <Route path="/our-values" element={<OurValuesWithAgeVerification/>} />
      <Route path="/sustainability" element={<SustainabilityWithAgeVerification/>} />
      <Route path="/diversity-inclusion" element={<DiversityInclusionWithAgeVerification/>} />
      <Route path="/cybersecurity" element={<GCCIndiaWithAgeVerification/>} />
      <Route path="/brewDat" element={<BUIndiaWithAgeVerification/>} />
      <Route path="/leaders" element={<GCCLeadersWithAgeVerification/>} />
      <Route path="/bu-leaders" element={<BULeadersWithAgeVerification/>} />
      <Route path="/ReCombinate" element={<ReCombinateWithAgeVerification/>} />
      <Route path="/tech-master" element={<LeapProgrammeWithAgeVerification/>} />
      <Route path="/managing" element={<BrewProgrammeWithAgeVerification/>} />
      <Route path="/lean-in-circle" element={<GlobalManagementTraineeProgrammeWithAgeVerification/>} />
      <Route path="/sales-visinories-programme" element={<SalesVisinoriesProgrammeWithAgeVerification/>} />
      <Route path="/supply-management-traineeship" element={<SupplyManagementTraineeshipWithAgeVerification/>} />
      <Route path="/our-brands" element={<OurBrandsWithAgeVerification/>} />
      <Route path="/404" element={<FourNotFourWithAgeVerification/>} />
      <Route path="/about" element={<AboutWithAgeVerification/>} />
      <Route path="/contact" element={<ContactWithAgeVerification/>} />
      <Route path="/gcc-benefits" element={<GCCBenefitsWithAgeVerification/>} />
      <Route path="/gcc-better-world-initiatives" element={<GCCBetterWorldInitiativesWithAgeVerification/>} />
      <Route path="/gcc-our-locations" element={<GCCLocationsWithAgeVerification/>} />
      <Route path="/bu-benefits" element={<BUBenefitsWithAgeVerification/>} />
      <Route path="/bu-better-world-initiatives" element={<BUBetterWorldInitiativesWithAgeVerification/>} />
      <Route path="/bu-our-locations" element={<BULocationsWithAgeVerification/>} />
      <Route path="/innovation" element={<FinalWithAgeVerification/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/cookie_policy" element={<CookiePolicy/>} />
      
      
      
    </Routes>
  </BrowserRouter>
);
