import React from "react";
import solutions from '../../assets/images/BU_India/solutions.jpeg';
class Solutions extends React.Component {
    render() {
        return(
            <div class="bu-common-bu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div class="bu-common-main">
                                <div class="bu-left">
                                    <h4 class="d-block d-sm-none">Solutions</h4>
                                    <div class="image">
                                        <img alt="Solutions" src={solutions} width="100%" />
                                    </div>
                                </div>
                                <div class="bu-right">
                                    <h4 class="d-none d-sm-block">Solutions</h4>
                                    <p>Our IT &amp; Solutions team solves a variety of challenges, from big-data analytics, to order optimization, to driving efficiencies in our network of capability centers. This team is responsible for transformational projects that will shape our company for years to come.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Solutions;