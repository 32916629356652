import React from "react";
import Slider from "react-slick";
import budwiser_0_0 from '../../assets/images/our_brands/budwiser_0_0.png';
import heogaarden_0_0 from '../../assets/images/our_brands/heogaarden_0_0.png';
import heogaarden_rose from '../../assets/images/our_brands/heogaarden_rose.png';
import { Link } from "react-router-dom";
class NonAlochoholic extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div class="beers-brands">
                <div class="container">
                    <div class="row">
                        <div class="col-md-1 col-xs-12 col-sm-1">
                            <h3 class="rotate-text">NON&nbsp;ALCHOHOLIC</h3>
                        </div>
                        <div class="col-md-11 col-xs-12 col-sm-11">
                            <div class="beers-list non-alchoholic-banner">
                                <Slider {...settings}>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={budwiser_0_0} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Stella Artois</h3>
                                                                <div>
                                                                    <Link to="www.stellaartois.com" target="blank" tabindex="0">www.stellaartois.com</Link>
                                                                </div>
                                                                <p>Stella Artois was initially crafted as a Christmas present for the residents of Leuven, Belgium. The beer's name holds significance, with "Stella" meaning "Star" in Latin and "Artois" the surname of its talented brewmaster, Sebastian Artois. Known for its pleasantly bitter and refreshing taste, Stella Artois boasts a crisp finish that leaves a lasting impression.</p>
                                                                <p>Stella Artois' crisp, refreshing flavor pairs well with intensely flavored Thai, Asian Fusion, and Indian dishes.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={heogaarden_0_0} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Leffe</h3>
                                                                <div>
                                                                    <Link to="www.leffe.com" target="blank" tabindex="0">www.leffe.com</Link>
                                                                </div>
                                                                <p>Leffe, an ale brewed 800 years ago by monks in Leffe Abbey, Dinant, Belgium, provided a safer alternative to water during a time of plague.</p>
                                                                <p>Today, Leffe is brewed in Leuven, and all Leffe variants are fermented with the same unique yeast, offering harmony with notes of vanilla, spicy clove/smoke, banana, and ripe fruits. Distinct ingredients like biscuit malts, roasted malts, and red fruits enhance the signature character of each variant.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="list-card">
                                            <div class="flip-card">
                                                <div class="flip-card-inner">
                                                    <div class="flip-card-front">
                                                        <div class="image">
                                                            <img src={heogaarden_rose} />
                                                        </div>
                                                    </div>
                                                    <div class="flip-card-back">
                                                        <div class="flip-inner">
                                                            <div class="flip-inner-main">
                                                                <h3>Hoegaarden</h3>
                                                                <div>
                                                                    <Link to="www.hoegaarden.com" target="blank" tabindex="0">www.hoegaarden.com</Link>
                                                                </div>
                                                                <p>Hoegaarden pronounced who-gar-den, traces its origins back to 1445 to a small Belgian village where dedicated monks crafted a unique wheat beer. Through meticulous experimentation, the monks discovered the perfect blend of orange peel and coriander.</p>
                                                                <p>Hoegaarden has gained popularity and garnered numerous prestigious awards for its exceptional quality and taste. This naturally unfiltered beer has refreshing fruity, floral, and citrus flavors. It boasts a bright and easy-to-drink profile.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default NonAlochoholic;