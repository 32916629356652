import React from "react";
import sustaibility_icon_5 from '../../assets/images/sustainability/sustainability_5.png';
import sustaibility_icon_6 from '../../assets/images/sustainability/sustainability_6.png';
import sustaibility_icon_7 from '../../assets/images/sustainability/sustainability_7.png';
import sustaibility_icon_8 from '../../assets/images/sustainability/sustainability_8.png';

class SustainabilityGoals extends React.Component {
    render() {
        return(
            <div className="common-cp-head sustainability-list">
                <div className="container">
                    <div className="row">
                        <p className="font_style_cls" style={{marginBottom:'35px'}}>Brewing quality beer starts with the best ingredients. This requires a healthy, natural environment, as well as thriving communities. We are building a company to last, bringing people together for a Better World, now and for the next 100+ years. That's why sustainability isn't just part of our business, it is our business.</p>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h2>2025 Sustainability Goals</h2>
                            <p className="content">Our 2025 Sustainability Goals are our most ambitious public commitments yet. Introduced in March 2018, the goals aim for holistic environmental and social impact and drive transformational change across our entire value chain. Our company’s leadership and a cross-functional team of nearly 100+ colleagues designed these goals with the next 100 years in mind, understanding that bold action built on measurable results is the key to resilience and lasting positive impact.</p>
                            {/* <ul className="sustainability-list sustainability-list-btm">
                                <li><img alt="Water" src={sustaibility_icon_5} /></li>
                                <li><img alt="Climate" src={sustaibility_icon_6} /></li>
                                <li><img alt="Package" src={sustaibility_icon_7} /></li>
                                <li><img alt="Agriculture" src={sustaibility_icon_8} /></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SustainabilityGoals;