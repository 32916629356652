import React from 'react';
import Banner from '../components/BUBenefits/Banner';
import BenefitsList from '../components/BUBenefits/BenefitsList';
import Rewarding from '../components/BUBenefits/Rewarding';
import ViewMore from '../components/BUBenefits/ViewMore';
import Layout2 from '../components/Layout/Layout2';
import ViewAllJobsDreamBig from '../components/BUIndia/ViewAllJobsDreamBig';

class BUBenefits extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <Rewarding />
            <BenefitsList />
            <ViewMore />
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default BUBenefits;