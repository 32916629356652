import React from "react";





import sandeepBalan from '../../assets/images/GCC_Leaders/sandeepBalan.jpeg';
import Runa from "./LeadersList/Runa";
import JoannaMoraes from "./LeadersList/JoannaMoraes";
import SanjeevRavindranathan from "./LeadersList/SanjeevRavindranathan";
import MarceloFonseca from "./LeadersList/MarceloFonseca";
import PrafullaSomani from "./LeadersList/PrafullaSomani";
import SajithKurup from "./LeadersList/SajithKurup";
import AlexanderKomrakov from "./LeadersList/AlexanderKomrakov";
import VijoeMathew from "./LeadersList/VijoeMathew";
import SumanaGM from "./LeadersList/SumanaGM";
import MadhurBhatia from "./LeadersList/MadhurBhatia";
import SandeepBalan from "./LeadersList/SandeepBalan";

class LeadersList extends React.Component {
    render() {
        return(
            <div className="leaders-list">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div class="common-table">
                                
                                <div class="common-table-right leader-new-list">
                                    <div class="row">
                                    <SajithKurup />
                                    <PrafullaSomani />
                                        <Runa />
                                        {/* <JoannaMoraes /> */}
                                        <SanjeevRavindranathan />
                                        <MarceloFonseca />
                                        
                                        
                                        <AlexanderKomrakov />
                                        {/* <VijoeMathew />
                                        <SumanaGM />
                                        <MadhurBhatia />
                                        <SandeepBalan /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default LeadersList;