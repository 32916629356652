import React from "react";
import ParentalLeave from '../../assets/images/BUBenefits/ParentalLeave.png';
import Mentoring from '../../assets/images/BUBenefits/Mentoring.png';
import Growth from '../../assets/images/BUBenefits/Growth.png';
import Development from '../../assets/images/BUBenefits/Development.png';
import Leadership from '../../assets/images/BUBenefits/Leadership.png';
import Rewards from '../../assets/images/BUBenefits/Rewards.png';
import Wellness from '../../assets/images/BUBenefits/Wellness.png';
import BeerVouchers from '../../assets/images/BUBenefits/BeerVouchers.png';
class BenefitsList extends React.Component {
    render() {
        return(
            <div class="bu-bhq-benefits">
                <div class="bu-india-head">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-xs-12 col-sm-12">
                                <ul class="bu-bhq-list-icon">
                                    <li data-aos="fade-up" data-aos-duration="200" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="PARENTAL LEAVE" src={ParentalLeave} />
                                            </div>
                                            <h4>PARENTAL LEAVE</h4>
                                            <p>When your family grows, enjoy time off to make the most of it.</p>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="300" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="MENTORING" src={Mentoring} />
                                            </div>
                                            <h4>MENTORING</h4>
                                            <p>Build your network and learn from our leaders.</p>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="400" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="GROWTH" src={Growth} />
                                            </div>
                                            <h4>GROWTH</h4>
                                            <p>Advance at the pace of your own talent.</p>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="500" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="DEVELOPMENT" src={Development} />
                                            </div>
                                            <h4>DEVELOPMENT</h4>
                                            <p>Learn new things every day through formal and informal training.</p>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="200" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="LEADERSHIP" src={Leadership} />
                                            </div>
                                            <h4>LEADERSHIP</h4>
                                            <p>Lead from day one. Whether it's a project, a team or a market.</p>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="300" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="REWARDS" src={Rewards} />
                                            </div>
                                            <h4>REWARDS</h4>
                                            <p>Earn competitive pay and be rewarded for delivering results.</p>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="400" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="WELLNESS" src={Wellness} />
                                            </div>
                                            <h4>WELLNESS</h4>
                                            <p>Your mental, emotional and physical health is important to us. We have robust benefits to fit all different lifestyles.</p>
                                        </div>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="500" class="aos-init">
                                        <div class="details">
                                            <div class="image">
                                                <img alt="BEER VOUCHERS" src={BeerVouchers} />
                                            </div>
                                            <h4>BEER VOUCHERS</h4>
                                            <p>Cheers! Treat yourself to our most loved beers.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BenefitsList;