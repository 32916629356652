import React from "react";
import supply_logistics from '../../assets/images/BU_India/supply_logistics.jpeg';
class SupplyLogistics extends React.Component {
    render() {
        return(
            <div class="bu-common-bu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div class="bu-common-main">
                                <div class="bu-right">
                                    <h4>Supply &amp; Logistics</h4>
                                    <p class="d-none d-sm-block">Our Supply &amp; Logistics team is responsible for brewing, packaging, technical services, and logistics. Working in supply means meeting high standards of safety, quality, and efficiency. These are the people who brew and deliver our beloved beers.</p>
                                </div>
                                <div class="bu-left">
                                    <div class="image">
                                        <img alt="Supply &amp; Logistics" src={supply_logistics} width="100%" />
                                    </div>
                                    <p class="d-block d-sm-none">Our Supply &amp; Logistics team is responsible for brewing, packaging, technical services, and logistics. Working in supply means meeting high standards of safety, quality, and efficiency. These are the people who brew and deliver our beloved beers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SupplyLogistics;