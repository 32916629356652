import React from "react";
import home_video from '../../assets/videos/Home-Banner.mp4';
class HomeBanner extends React.Component {
    render() {
        return(
            <div className="home-banner">
                <video autoPlay muted loop id="homeVideoBanner">
                    <source src={home_video} type="video/mp4" />
                </video>
                <div className="banner-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-sm-12">
                                <h1>WE <span>DREAM</span> BIG TO CREATE <br/>A FUTURE WITH MORE CHEERS</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HomeBanner;