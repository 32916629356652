import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const withAgeVerification = (Component) => {
  return () => {
    // const oneHourFromNow = new Date();
    // oneHourFromNow.setTime(oneHourFromNow.getTime() + 60 * 60 * 1000);
    // Cookies.set("ageVerified", "true", { expires: oneHourFromNow }); // You can use local storage if you prefer
    const ageVerified = Cookies.get("ageVerified");
    
    if (ageVerified === "true") {
      
      if (window.location.pathname === "/") {
        return <Navigate to="/home" replace />;
      }
      // If age is verified, render the component as usual
      return <Component />;
    } else {
      // If age is not verified, redirect back to the age gate page
      return <Navigate to="/" />;
    }
  };
};

export default withAgeVerification;