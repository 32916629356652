import React from "react";
import Slider from "react-slick";
import global_mt_programme from '../../assets/images/home/Leanincircles.png';
import leap_programme from '../../assets/images/home/Managing.png';
import sales_visionaries_programme from '../../assets/images/home/TechMaster.png';
import supply_mt_programme from '../../assets/images/home/Recombinate.png';
import brew_programme from '../../assets/images/home/brew_programme.png';
import { Link } from "react-router-dom";
class CampusProgrammes extends React.Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            centerPadding: '30px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return(
            <div className="campus-programmes">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <div className="what-we-do-heading">
                                <h3 className="common-heading">EXPLORE OUR <span>PROGRAMS</span></h3>
                            </div>
                        </div>
                        </div>
                        <div className="row campus-prog">
                        <div className="col-md-3 col-xs-3 col-sm-3 ">
                            <div className="image">
                                <img src={global_mt_programme } alt={leap_programme} />
                            </div>
                            <div className="details">
                                <h4>Lean In Circles</h4>
                                <p>It is a beacon of empowerment for women across the organization. Backed by the DEI team, it serves as a sanctuary where women employees can openly share their challenges, seek and offer advice, and collectively celebrate triumphs. </p>
                                <Link to="/lean-in-circle">Know More</Link>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-3 col-sm-3 ">
                            <div className="image">
                                <img src={leap_programme} alt={leap_programme} />
                            </div>
                            <div className="details">
                                <h4>Managing@ABI</h4>
                                <p>Our experiential leadership program nurtures new managers, enhancing their skills and understanding of our Leadership Capabilities and People Cycle. It is designed for entry-level managers handling teams, projects, or initiatives that require bringing together many people.</p>
                                <Link to="/managing">Know More</Link>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-3 col-sm-3 ">
                            <div className="image">
                                <img src={sales_visionaries_programme } alt={leap_programme} />
                            </div>
                            <div className="details">
                                <h4>AB InBev Tech Master</h4>
                                <p>This unique learning journey delves deep into our organization's functions. More than training, it's an immersive experience covering Procurement, Supply, Sustainability, and Logistics, offering an exclusive glimpse into our operations.</p>
                                <a to="/tech-master">Know More</a>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-3 col-sm-3 ">
                            <div className="image">
                                <img src={supply_mt_programme} alt={leap_programme} />
                            </div>
                            <div className="details">
                                <h4>ReCombinate</h4>
                                <p>This collaborative initiative with TAU Ventures and the IIA is designed for Israeli startups with robust, multi-use technologies capable of addressing AB InBev's current and future business challenges.</p>
                                <Link to="/ReCombinate">Know More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CampusProgrammes;