import React from 'react';
import Layout2 from '../components/Layout/Layout2';
import Banner from '../components/OurValues/Banner';
import OurPrinciples from '../components/OurValues/OurPrinciples';
import PrinciplesBanner from '../components/OurValues/PrinciplesBanner';

class OurValues extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <PrinciplesBanner />
            <OurPrinciples />
        </Layout2>
      </>
    );
  }
}

export default OurValues;