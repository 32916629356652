import React from "react";
import legal_corporate_affairs from '../../assets/images/BU_India/legal_corporate_affairs.jpeg';
class CorporateAffairs extends React.Component {
    render() {
        return(
            <div class="bu-common-bu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div class="bu-common-main">
                                <div class="bu-right">
                                    <h4>Corporate Affairs</h4>
                                    <p class="d-none d-sm-block">Our Legal &amp; Corporate affairs team protects our company and enables growth through mergers and acquisitions, innovative patents, compliance, and more. They respond quickly to situations around the world, create and share content about AB InBev, our people, our heritage, and our sustainability work.</p>
                                </div>
                                <div class="bu-left">
                                    <div class="image">
                                        <img alt="Corporate Affairs" src={legal_corporate_affairs} width="100%" />
                                    </div>
                                    <p class="d-block d-sm-none">Our Legal &amp; Corporate affairs team protects our company and enables growth through mergers and acquisitions, innovative patents, compliance, and more. They respond quickly to situations around the world, create and share content about AB InBev, our people, our heritage, and our sustainability work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CorporateAffairs;