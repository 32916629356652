import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Oran from '../../../assets/images/GCC_Leaders/Oran.png';
class SanjeevRavindranathan extends React.Component {

    state = {
        isOpen: false
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return(
            <div class="col-md-3 col-xs-12 col-sm-3">
                <div class="profile-details" onClick={this.openModal}>
                    <div class="image">
                        <img alt="Sanjeev Ravindranathan" src={Oran} />
                    </div>
                    <div class="details">
                        <h4>Oran Yitzhak</h4>
                        <p>Global Director, Defensive Services</p>
                    </div>
                </div>
                <Modal className="leaders-modal-popup" show={this.state.isOpen} onHide={this.closeModal} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="details-modal-left">
                            <img alt="Runa Dhawan" src={Oran} />    
                        </div>
                        <div class="details-modal-right">
                            <h4>Oran Yitzhak</h4>
                            <p class="sub-head">Global Director, Defensive Services</p>
                            <p class="content">Oran strengthens AB InBev's cybersecurity infrastructure through his leadership, ensuring the smooth continuation of business operations on a global scale. He collaborates with teams worldwide, focusing on a range of crucial tasks such as threat hunting, forensics, malware analysis, purple teaming, and threat intelligence.</p>
                            <div className="bottom_text_cls">
                            <p class="content">Before joining the company in 2019 as a Global Threat Hunting Manager, Oran worked as a cybersecurity consultant for 5 years. It immensely contributed to his knowledge and expertise in this specialized field.</p>
                            <p class="content">Oran holds a bachelor's degree in Business & Information Systems. Drawing upon his personal interest in psychology and unwavering dedication to professional development, he is currently undertaking a course focused on the psychology of performance and cognitive studies.</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default SanjeevRavindranathan;