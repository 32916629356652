import React from 'react';
import Banner from '../components/BULeaders/Banner';
import Heading from '../components/BULeaders/Heading';
import LeadersList from '../components/BULeaders/LeadersList';
import Layout2 from '../components/Layout/Layout2';

class BULeaders extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <Heading />
            <LeadersList />
        </Layout2>
      </>
    );
  }
}

export default BULeaders;