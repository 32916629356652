import React from "react";
import Layout2 from "../components/Layout/Layout2";

import { useNavigate } from "react-router-dom"; // Import useNavigate

function PrivacyPolicy() {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleLinkClick = () => {
        // Use navigate to navigate to "/cookie_policy"
        navigate("/cookie_policy");
    };
  return (
    <div>
      <Layout2>
        <section className="top_pera">
          <div className="container">
            <div className="row">
             <div className="col-12">
                <h1>PRIVACY POLICY</h1>
             <p>
                Dear visitor, thank you for visiting our website. Take a few
                minutes to read this document that explains how we use your
                personal data that is collected via this website.
              </p>
             </div>
             <div className="col-12">
                <h2>Contents</h2>
                <ul className="QTag">
                    <li><a href="#WhoWeAre"> Who are we? </a></li>
                    <li><a href="#DoCollect"> What personal data do we collect?</a></li>
                    <li><a href="#DrinkingAge"> Legal Drinking Age</a></li>
                    <li><a href="#SocialLinks"> Social Links</a></li>
                    <li><a href="#UsePersonalData"> How do we use your personal data?</a></li>
                    <li><a href="#KeepPersonalData"> How do we keep your personal data secure?</a></li>
                    <li><a href="#SharePersonalData"> Who do we share your personal data with?</a></li>
                    <li><a href="#TransferPersonalData"> Do we transfer your personal data to other countries?</a></li>
                    <li><a href="#Yourrights"> Your rights (and how to exercise them!)</a></li>
                    <li><a href="#PrivacyNotice"> Changes to our Privacy Notice</a></li>
                </ul>
             </div>
             <div className="col-12 fullSection" id="WhoWeAre">
                <h2>Who are we</h2>
                <p>
                Your personal data is collected and processed by Weissbeerger LTD/ AB InBev Israel as the owner of the database, with registered office at Menachem Begin 52, Tel Aviv  and company number 0433.666.709. Hereinafter referred to as “we”, “us” or “our”.
                </p>
                <p>
                In the course of our business and during our interaction with website visitors and applicants we may process personal data of individuals (hereinafter “you” or your”).
                </p>
             </div>
             <div className="col-12 fullSection" id="DoCollect">
                <h2>What personal data do we collect</h2>
                <p>By visiting our website, we may collect your data in the following ways:</p>
                <ul>
                    <li>
                        Through our age gate verification we will ask for your birth date. This will allow us to check whether you are old enough to get access to the content that can be found on this website.
                    </li>
                    <li>
                    When you want to apply for one of our open positions we will process your personal data to evaluate your application. This includes your CV and contact details such as your name, e-mail, telephone number and LinkedIn-page. For this we will use a dedicated recruitment platform. More information on the processing of your personal data via this platform you can find here.
                    </li>
                    <li>
                    Through cookies for security reasons, to ensure our website to function properly. For more information on the cookies that are placed on your device, please read our Cookie policy.
                    </li>
                </ul>
                <p>
                We will retain your personal information only as long as necessary for fulfilment of the purposes described below or as required by law. We will actively review the personal data we hold and delete it securely, or in some cases anonymize it, when there is no longer a legal or business need to keep it.
                </p>
             </div>
             <div className="col-12 fullSection" id="DrinkingAge">
                <h2>Legal Drinking Age</h2>
                <p>
                This website is not intended for individuals under the legal drinking age. Therefore, we perform age checks upon visiting the website. Any data collected in this regard, will only be used for checking your age and not be further stored or linked to any other data. Please do not share or forward this website or its contents to anyone underage.
                </p>
             </div>
             <div className="col-12 fullSection" id="SocialLinks">
                <h2>Social Links</h2>
                <p>
                We place various social media buttons on our website in the form of links, such as Instagram and LinkedIn.
                </p>
                <p>
                We only display a button on our website and do not integrate any social media plugins. Personal data from you will only be transferred to the social media provider if you click on the button.
                </p>
                <p>
                If you are already logged into the social media service when you click on the social media button, the social media service may also use this data to identify your user name and possibly even your real name. We have no control over the extent, nature and purpose of such data processing by the social media service. Please note that the social media service is perfectly capable of using this data to create pseudonymised and even individualised user profiles.
                </p>
                <p>
                You can find further information about data protection on social media platforms in the privacy and cookie policies provided by these social media platforms.
                </p>
             </div>
             <div className="col-12 fullSection" id="UsePersonalData">
                <h2>How do we use your personal data</h2>
                <p>The information detailed in the previous section is used by us in specific ways as follows:</p>
                <table>
        <thead>
            <tr>
                <th>Legal ground</th>
                <th>Purpose</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>When you give us your consent to:</td>
                <td>
                    <ul>
                        <li>To evaluate your application when you want to apply for one of our open positions.</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>As required to conduct our business and pursue our legitimate interests, in particular to:</td>
                <td>
                    <ul>
                        <li>Ensure security and our website to function properly by placing strictly necessary cookies. For more information on the cookies that are placed on your device, please read our<span onClick={handleLinkClick} style={{ cursor: 'pointer', color: '#0D6EFD', textDecoration: 'underline',display: 'inline', padding:'0 0 0' }}>Cookie policy</span>.</li>
                        <li>If we merge with or are acquired by another company, or if all or a portion of our assets are acquired by another company, your information will likely be disclosed to our advisers and prospective purchaser's advisers and may be assets that are transferred to the new owner.</li>
                        <li>Defend our rights or property and protect the rights and interests of our consumers, users, and employees.</li>
                        <li>To verify whether you are old enough to get access to the content that can be found on this website.</li>
                        <li>Comply with applicable law.</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>To comply with our legal obligations to:</td>
                <td>
                    <ul>
                        <li>To verify whether you are old enough to get access to the content that can be found on this website.</li>
                        <li>Comply with applicable law.</li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
             </div>
             <div className="col-12 fullSection" id="KeepPersonalData">
                <h2>How do we keep your personal data secure?</h2>
                <p>We employ a wide range of measures to protect your personal data as comprehensively as possible.</p>
                <p>
                In addition to the commitment of our employees to secrecy and a careful selection and monitoring of our partners – who are bound to comply with high security standards for the protection of your personal data – we also secure our operating environment adequately. To protect your personal data against unwanted access, we employ measures such as encryption of personal data in transit, access controls, firewalls, among others, and we strive to stay updated to face future challenges. However, the internet is never 100% secure and we cannot guarantee that your personal data will be absolutely safe from intrusion by others. We encourage you to use strong, unique passwords and never share your account with others.
                </p>
                <p>
                When you click a link to a third-party site, you will be leaving our site and we don't control or endorse what is on third-party sites.  
                </p>
             </div>
             <div className="col-12 fullSection" id="SharePersonalData">
                <h2>Who do we share your personal data with?</h2>
                <p>We will not share with third parties the data collected about you as part of the use of this website, except in the following cases:</p>
                <ul>
                    <li>
                        <strong>To reputable companies</strong> that help us run our business by providing services such as our recruitment platform through which you can apply for open positions. We only engage with companies that comply with our high standards, and we put in place safeguards to ensure adequate protection.
                    </li>
                    <li>
                        <strong>Our affiliates</strong>, the companies that are part of the AB InBev family.
                    </li>
                    <li>
                        <strong>Other parties when required by law</strong> to protect our rights, property, or safety and that of our clients, visitors to our website, and/or the public.
                    </li>
                    <li>
                        <strong>Other parties in connection with corporate transactions</strong> such as mergers, acquisitions, sale of assets, or any other transaction that results in a change in control or ownership of our company, in whole or in part.
                    </li>
                </ul>
                <p>You can rest assured that we'll only disclose your personal data for the purposes mentioned in section 3 and that your data security is being taken care of, as explained further in section 4.</p>
                <p>
                In addition to the sharing described in this Privacy Notice, we may also share information about you whenever you consent to or direct such sharing.
                </p>
             </div>
             <div className="col-12 fullSection" id="TransferPersonalData">
                <h2>Do we transfer your personal data to other countries?</h2>
                <p>We may transfer your personal data to partners or affiliates located in countries other than the one in which you live (but only as necessary for the purposes detailed in section 3). This may include transfers to third countries providing an adequate level of protection as well as countries which might not be considered to offer a level of data protection comparable to that of the country where your personal data has been collected. In such cases, rest assured that we will transfer your personal data with appropriate measures to keep it safe.  For more information on these international transfers and/or to obtain a copy of the safeguards in place, please contact <a href="mailto:dataprotectionofficer_eu@ab-inbev.com">dataprotectionofficer_eu@ab-inbev.com.</a> </p>
             </div>
             <div className="col-12 fullSection" id="Yourrights">
                <h2>Your rights (and how to exercise them!)</h2>
                <ul>
                    <li>
                        To require us to update or correct any inaccurate personal data we hold about you.
                    </li>
                    <li>
                        To stop processing your personal data for the purpose of direct marketing communications.
                    </li>
                    <li>
                        To withdraw your consent.
                    </li>
                    <li>
                        To request access to the data we hold about you or to inform you further about how we use it.
                    </li>
                    <li>
                        To require us to delete any inaccurate personal data.
                    </li>
                    <li>
                        To object to the processing of your personal data.
                    </li>
                    <li>
                        To restrict our processing.
                    </li>
                    <li>
                        To transmit your personal data to you or to transfer or have them transferred to another company.
                    </li>
                </ul>
                <p>
                If you want to exercise your rights or have any comments, queries, or complaints about our collection or use your personal data please send an email to dataprotectionofficer_eu@ab-inbev.com. Your request will be processed by our team and a response will be provided in writing as soon as possible and certainly within 30 calendar days of the day we received the request. If you remain dissatisfied, you can logde a complaint with a competent court or your local authority:  Privacy Protection Authority, Tel Aviv Government Complex, P.O. BOX 33503, Tel-Aviv 6133401, ppa@justice.gov.il.
                </p>
             </div>
             <div className="col-12 fullSection" id="PrivacyNotice">
                <h2>Changes to our Privacy Notice</h2>
                <p>We keep our Privacy Notice under regular review and any changes we may make in the future will be posted here.</p>
                <p>
                This Privacy Notice was last updated on 16/01/2023 and it is version number 1.
                </p>
             </div>
            </div>
          </div>
        </section>
      </Layout2>
    </div>
  );
}

export default PrivacyPolicy;
