import React from 'react';
import ViewAllJobs from '../components/Common/ViewAllJobs';
import AllDiverseSlates from '../components/DiversityInclusion/AllDiverseSlates';
import Banner from '../components/DiversityInclusion/Banner';
import DiversicyAwards from '../components/DiversityInclusion/DiversicyAwards';
import OurCommitment from '../components/DiversityInclusion/OurCommitment';
import Our_D_I_Initiatives from '../components/DiversityInclusion/Our_D_I_Initiatives';
import ParentalPolicies from '../components/DiversityInclusion/ParentalPolicies';
import SafeWorkPlace from '../components/DiversityInclusion/SafeWorkPlace';
import Layout2 from '../components/Layout/Layout2';

class DiversityInclusion extends React.Component {
  render() {
    return (
      <>
        <Layout2>
          <Banner />
          <OurCommitment />
          <SafeWorkPlace />
          <Our_D_I_Initiatives />
          <ParentalPolicies />
          <AllDiverseSlates />
          <DiversicyAwards />
          <ViewAllJobs />
        </Layout2>
      </>
    );
  }
}

export default DiversityInclusion;