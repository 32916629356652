import React from 'react';
import Banner from '../components/GCCBetterWorldInitiatives/Banner';
import List from '../components/GCCBetterWorldInitiatives/List';
import ViewMore from '../components/GCCBetterWorldInitiatives/ViewMore';
import ViewAllJobsDreamBig from '../components/GCCIndia/ViewAllJobsDreamBig';
import Layout2 from '../components/Layout/Layout2';

class GCCBetterWorldInitiatives extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <List />
            <ViewMore />
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default GCCBetterWorldInitiatives;