import React from "react";
import procurement from '../../assets/images/BU_India/procurement.jpeg';
class Procurement extends React.Component {
    render() {
        return(
            <div class="bu-common-bu">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 no-padding">
                            <div class="bu-common-main">
                                <div class="bu-left">
                                    <h4 class="d-block d-sm-none">Procurement</h4>
                                    <div class="image">
                                        <img alt="Procurement" src={procurement} width="100%" />
                                    </div>
                                </div>
                                <div class="bu-right">
                                    <h4 class="d-none d-sm-block">Procurement</h4>
                                    <p>Our Procurement team selects vendors, negotiates contracts, and purchases goods. They drive our sustainability agenda across our business and with our supply base in order to achieve our 2025 goals on climate action, circular packaging, water stewardship &amp; smart agriculture.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Procurement;