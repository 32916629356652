import React from "react";
class Heading extends React.Component {
    render() {
        return(
            <div className="common-heading-sub sub_do">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <h3>TOGETHER WE CAN <span>ACHIEVE ANYTHING</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Heading;