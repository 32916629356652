import React from 'react';

import Banner from '../components/BUInnovation/Banner';
import Heading from '../components/BUInnovation/Heading';
import Operations from '../components/BUInnovation/Operations';
import Layout2 from '../components/Layout/Layout2';
import ViewAllJobsDreamBig from '../components/GCCIndia/ViewAllJobsDreamBig';
import CardRowInnovation from '../components/GCCIndia/CardRowInnovation';



class GCCIndia extends React.Component {
  render() {
    return (
      <>
        <Layout2>
            <Banner />
            <Heading />
            <Operations />
            
            <CardRowInnovation></CardRowInnovation>
            {/* <Analytics /> */}
            {/* <Tech /> */}
            {/* <Awards /> */}
            {/* <MoreAbout /> */}
            <ViewAllJobsDreamBig />
        </Layout2>
      </>
    );
  }
}

export default GCCIndia;