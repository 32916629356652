import React from "react";
class Banner extends React.Component {
    render() {
        return(
            <section className="common-banner our-history-banner">
                <div className="common-banner-inner">
                    <h1> <p className="yel-col">Our Heritage</p>  <span className="red-text">  Six Centuries of Brewing</span></h1>
                </div>
            </section>
        );
    }
}
export default Banner;